import Joi from 'joi';
import {
  HEIGHT,
  LENGTH,
  QUANTITY,
  WEIGHT,
  WIDTH,
} from '../../../constants/forms';
import { PARCEL_DESCRIPTION } from '../../../constants/strings';

export const parcelSchema = () =>
  Joi.object({
    parcelId: Joi.string(),
    _weight: Joi.number()
      .greater(0)
      .max(Joi.ref('$maxWeight'))
      .label(WEIGHT)
      .messages({
        'number.max': '{#label} must be less than or equal to {$maxWeight}',
      }),
    _quantity: Joi.number()
      .min(1)
      .max(Joi.ref('$maxParcelNumber'))
      .label(QUANTITY)
      .messages({
        'number.max':
          '{#label} must be less than or equal to {$maxParcelNumber}',
      }),
    _width: Joi.number().greater(0).max(100).label(WIDTH),
    _height: Joi.number().greater(0).max(100).label(HEIGHT),
    _length: Joi.number().greater(0).max(100).label(LENGTH),
    _description: Joi.string()
      .allow('', null)
      .max(45)
      .label(PARCEL_DESCRIPTION),
    products: Joi.array().items(Joi.object()).allow(null),
  });
