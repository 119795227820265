import { isBoolean, min, add, multiply, get, max } from 'lodash';

// NOTE: Not removed yet because logic is not fully finalized
/**
 *
 * @param {Array} networks - Array of networks
 * @param {boolean} dropAtShop - Bollean indicator of whether parcel goes to shop
 * @returns {number} - Minimum price based on parcel destination and networks
 */
export const getMinPriceFromNetworksByDropAtShop = (networks, dropAtShop) =>
  networks.reduce(
    (acc, curr) =>
      min([
        acc,
        isBoolean(dropAtShop) && dropAtShop
          ? curr.price.fromShop
          : curr.price.fromDoor,
      ]),
    100
  );
/**
 *
 * @param {Array} networks - Array of networks
 * @returns {number} - Minimum price fromShop from the networks
 */
export const getMinShopPriceFromNetworks = networks =>
  networks.reduce((acc, curr) => min([acc, curr.price.fromShop]), 100);

/**
 *
 * @param {Array} networks - Array of networks
 * @returns {number} - Minimum price fromDoor from the networks
 */
export const getMinDoorPriceFromNetworks = networks =>
  networks.reduce((acc, curr) => min([acc, curr.price.fromDoor]), 100);

/**
 *
 * @param {Array} networks - Array of networks
 * @param {number} totalWeight - Total weight of the parcels
 * @returns {number} - Minimum price from the networks by totalWeight assuming it would be fromShop price
 */
export const getMinPriceFromNetworks = (networks, totalWeight) => {
  const price = networks.reduce((acc, curr) => {
    if (curr.price.fromShop) {
      return min([acc, curr.price.fromShop]);
    } else {
      return acc;
    }
  }, 100);

  // TODO: 0.25 should come from network's extraPerKilo
  return totalWeight > 2
    ? (price + (totalWeight - 2) * 0.25).toFixed(2)
    : price;
};

/**
 *
 * @param {Array} networks - Array of networks
 * @param {number} shipment - Shipment
 * @returns {Array} - Available networks based on number of parcels and their weights
 */
export const getNetworksByParcelSizeAndWeight = (networks = [], shipment) =>
  networks.filter(
    ({ networkDetails }) =>
      shipment.outboundConsignment.parcels.every(
        ({ _weight }) => _weight <= networkDetails?.maxWeight / 1000
      ) &&
      shipment.outboundConsignment.numberOfParcels <= networkDetails?.maxParcels
  );

/**
 *
 * @param {Array} networks - Array of networks
 * @param {boolean} dropAtShop - Boolean indicator of whether parcel goes to shop
 * @returns {Array} - Sorted array of networks by price based on whether parcel goes to shop
 */
export const sortNetworksByPrice = (networks, dropAtShop) =>
  networks.sort((prev, curr) => {
    if (dropAtShop) {
      return prev?.price?.fromShop - curr?.price?.fromShop;
    } else {
      return prev?.price?.fromDoor - curr?.price?.fromDoor;
    }
  });

/**
 *
 * @param {object} param
 * @param {Array} param.networks - Array of networks
 * @param {boolean} param.dropAtShop - Boolean indicator of whether parcel goes to shop
 * @param {number} param.minDoorPrice - Minimum price from networks with availability to collect parcel from door
 * @param {number} param.minShopPrice - Minimum price from networks with availability to collect parcel from shop
 * @returns {Array} - Additional price to show for network based on input params
 */
export const getAdditionalPriceForNetwork = ({
  network,
  dropAtShop,
  minDoorPrice,
  minShopPrice,
}) => {
  if (dropAtShop) {
    if (network?.price.fromShop > minShopPrice) {
      return network?.price.fromShop - minShopPrice;
    }
  } else {
    if (network?.price.fromDoor > minDoorPrice) {
      return network?.price.fromDoor - minDoorPrice;
    }
  }

  return 0;
};

export const getParcelsTotalWeight = (parcels = []) =>
  parcels.reduce(
    (acc, curr) =>
      add(
        acc,
        multiply(
          Number.parseInt(curr._weight || 0),
          Number.parseInt(curr._quantity || 0)
        )
      ),
    0
  );

export const getParcelTotalQuantity = (parcels = []) =>
  parcels.reduce(
    (acc, curr) => add(acc, Number.parseInt(curr._quantity || 0)),
    0
  );

/**
 *
 * @param {Array} networks - List of networks
 * @returns Maximum weight and parcel number for networks
 */
export const getMaxWeightAndParcelNumberFromNetworks = (networks = []) =>
  networks.reduce((acc, curr) => {
    let maxWeight = acc.maxWeight;
    let maxParcelNumber = acc.maxParcelNumber;

    if (curr.networkDetails?.maxWeight) {
      maxWeight = max([maxWeight, curr.networkDetails?.maxWeight / 1000]);
    }
    if (curr.networkDetails?.maxParcels) {
      maxParcelNumber = max([maxParcelNumber, curr.networkDetails?.maxParcels]);
    }

    return { maxWeight, maxParcelNumber };
  }, {});

/**
 *

 * @param {boolean} dropAtShop
 * @param {object} param
 * @param {Array} param.networks - List of networks
 * @param {object} param.collectionPickup - Collection pickup point
 * @param {object} param.deliveryPickup - Delivery pickup point
 * @param {boolean} param. - Boolean indicator of whether parcel goes to shop
 * @returns
 */
export const getMaxWeightAndParcelNumber = (
  dropAtShop,
  { networks, collectionPickup, deliveryPickup }
) => {
  const collectionPickupMaxWeight =
    get(collectionPickup, 'pickupLocation.pickupLocationMaxWeight') / 10;
  const deliveryPickupMaxWeight =
    get(deliveryPickup, 'pickupLocation.pickupLocationMaxWeight') / 10;
  const collectionPickupMaxQuantity = get(
    collectionPickup,
    'pickupLocation.maxParcelQuantity'
  );
  const deliveryPickupMaxQuantity = get(
    deliveryPickup,
    'pickupLocation.maxParcelQuantity'
  );
  const { maxWeight, maxParcelNumber } =
    getMaxWeightAndParcelNumberFromNetworks(networks);

  let minMaxWeight = maxWeight;
  let minMaxParcelNumber = maxParcelNumber;

  if (dropAtShop) {
    minMaxWeight = min([
      collectionPickupMaxWeight || minMaxWeight,
      deliveryPickupMaxWeight || minMaxWeight,
      minMaxWeight,
    ]);
    minMaxParcelNumber = min([
      collectionPickupMaxQuantity || minMaxParcelNumber,
      deliveryPickupMaxQuantity || minMaxParcelNumber,
      minMaxParcelNumber,
    ]);
  }

  return {
    maxWeight: minMaxWeight || 30,
    maxParcelNumber: minMaxParcelNumber || 99,
  };
};
