import { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import Sentry, { getSentryUser } from '../../sentry';
import { createResponseInterceptor } from '../../apis/apis';
import Loader from '../Loader';
import Error from '../../pages/Auth/Error';
import { getAuthData } from './selectors';
import { AuthContext } from './Context';
import * as Actions from './actions';

const AuthProvider = props => {
  const dispatch = useDispatch();
  const { currentSession } = useSelector(getAuthData);
  const isSessionExpirationHandled = useRef(false);

  const value = useMemo(() => {
    const actions = bindActionCreators(Actions, dispatch);
    return {
      ...actions,
      currentSession,
    };
  }, [dispatch, currentSession]);

  useEffect(() => {
    Sentry.setUser(getSentryUser(currentSession?.uid));
  }, [currentSession?.uid]);

  useEffect(
    () => () => {
      isSessionExpirationHandled.current = false;
    },
    []
  );

  const fetchCurrentSession = useCallback(async () => {
    await dispatch(Actions.getCurrentSession()).unwrap();
  }, [currentSession?.uid]);

  const handleErrorListener = useCallback(
    async error => {
      if (
        error.response &&
        error.response.status === 401 &&
        !isSessionExpirationHandled.current
      ) {
        isSessionExpirationHandled.current = true;
        await dispatch(Actions.getCurrentSession()).unwrap();
        dispatch({ type: 'auth/clear' });
      } else {
        return Promise.reject(error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    createResponseInterceptor(response => response, handleErrorListener);
  }, []);

  return (
    <AuthContext.Provider value={value}>
      <Loader
        promiseFn={fetchCurrentSession}
        watch={currentSession?.uid}
        errorComponent={error => <Error values={{ error }} />}
      >
        {props.children}
      </Loader>
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;
