import { chain, get, cloneDeep } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';

import { OrderSliceSelectors } from '../../slice';
import { ShipmentHelper } from '../../helpers';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';

export const getInitialFormValues = createSelector(
  [OrderSliceSelectors.getValues],
  values => {
    const clonedValues = cloneDeep(values);
    return chain(clonedValues)
      .set(
        SHIPMENT_FIELDS.SENDER_USER_TYPE.KEY,
        get(values, SHIPMENT_FIELDS.SENDER_USER_TYPE.KEY) ||
          ShipmentHelper.getSenderUserType(values)
      )
      .set(
        SHIPMENT_FIELDS.RECEIVER_USER_TYPE.KEY,
        get(values, SHIPMENT_FIELDS.RECEIVER_USER_TYPE.KEY) ||
          ShipmentHelper.getReceiverUserType(values)
      )
      .set(
        SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY,
        get(values, SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY) || ''
      )
      .set(
        SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY,
        get(values, SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY) || ''
      )
      .set(
        SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY,
        get(values, SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY) || ''
      )
      .value();
  }
);

export const getRequiredFields = createSelector(
  [OrderSliceSelectors.getValues],
  () => [
    'outboundConsignment.collectionDetails.contactDetails.contactName',
    'outboundConsignment.collectionDetails.contactDetails.email',
    'outboundConsignment.collectionDetails.contactDetails.telephone',
    'outboundConsignment.deliveryDetails.contactDetails.contactName',
    'outboundConsignment.deliveryDetails.notificationDetails.email',
    'outboundConsignment.deliveryDetails.notificationDetails.mobile',
  ]
);
