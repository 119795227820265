import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { get, omit } from 'lodash';

import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  TextField,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

import {
  APPLY,
  YOUR_VOUCHER,
  ENTER_VOUCHER,
  VOUCHER_APPLIED,
  INVALID_VOUCHER,
  APPLIED_VOUCHER_$,
} from '../../constants/strings';
import { voucherSchema, validateWithJoi } from '../../utils/validators';
import { removeSpaces } from '../../utils/formatters';
import useErrorHandler from '../../hooks/useErrorHandler';
import { useCustomSnackbar } from '../../features/CustomSnackbar';
import { vouchersApis } from '../../apis';

const formInitialValue = { voucherCode: '' };

const Voucher = ({ voucher, setVoucher }) => {
  const [formValues, setFormValues] = useState(formInitialValue);
  const [errors, setErrors] = useState({});
  const [responseErrors, setResponseErrors] = useState();
  const voucherCode = formValues?.voucherCode;
  const snackbar = useCustomSnackbar();
  const { errorHandler } = useErrorHandler();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const isApplyDisabled =
    voucher ||
    !!validateWithJoi(formValues, voucherSchema).voucherCode ||
    !!responseErrors;

  const handleVoucherApply = useCallback(
    voucher => {
      setVoucher(voucher);
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...Object.fromEntries(searchParams),
          voucherId: voucher.voucherId,
        }).toString(),
      });
    },
    [searchParams]
  );

  const onApplyVoucher = async () => {
    try {
      const data = await vouchersApis.getVoucherByCode(voucherCode);
      snackbar.showSuccess({
        message: VOUCHER_APPLIED,
        variant: 'success',
      });
      setFormValues(formInitialValue);
      handleVoucherApply(data);
    } catch (error) {
      if (error.response.status === 404) {
        const errorMessage = get(
          error,
          'response.data.error.message',
          INVALID_VOUCHER
        );
        errorHandler(errorMessage, error);
        setResponseErrors(errorMessage);
      }
    }
  };

  const onBlur = () => {
    const newFormValues = {
      ...formValues,
      voucherCode: removeSpaces(voucherCode),
    };

    const validationErrors = validateWithJoi(newFormValues, voucherSchema);

    setFormValues(newFormValues);
    setErrors(validationErrors);
  };

  const onFieldChange = () => {
    const { name, value } = event.target;

    const newFormValues = {
      ...formValues,
      [name]: value,
    };

    setResponseErrors(null);
    setFormValues(newFormValues);
  };

  const loadVoucher = useCallback(async () => {
    const { voucherId } = Object.fromEntries(searchParams);
    try {
      const voucher = await vouchersApis.getVoucherById(voucherId);
      setVoucher(voucher);
    } catch (err) {
      deleteVoucher();
      snackbar.showWarning({
        message: INVALID_VOUCHER,
      });
    }
  }, [navigate, searchParams, snackbar, setVoucher]);

  const removeVoucherIdFromQueryParams = () => {
    const queryParams = Object.fromEntries(searchParams);

    navigate(
      {
        pathname: location.pathname,
        search: createSearchParams(omit(queryParams, 'voucherId')).toString(),
      },
      {
        replace: true,
      }
    );
  };

  const deleteVoucher = () => {
    removeVoucherIdFromQueryParams();
    setVoucher(null);
  };

  useEffect(() => {
    if (searchParams.get('voucherId')) {
      loadVoucher();
    }
  }, []);

  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography variant='h3' sx={{ width: '100%', marginBottom: '16px' }}>
        {YOUR_VOUCHER}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          height: '40px',
          marginBottom: 2,
        }}
      >
        <TextField
          name='voucherCode'
          placeholder={ENTER_VOUCHER}
          fullWidth={true}
          inputProps={{
            style: {
              padding: '9px 14px',
            },
          }}
          sx={{ marginRight: '16px' }}
          onChange={onFieldChange}
          onBlur={onBlur}
          onFocus={() => setErrors({})}
          error={voucherCode && !!(errors.voucherCode || responseErrors)}
          helperText={voucherCode && (errors.voucherCode || responseErrors)}
          value={voucherCode}
        />
        <Button
          type='submit'
          variant={isApplyDisabled ? 'outlined' : 'contained'}
          disabled={isApplyDisabled}
          onClick={onApplyVoucher}
        >
          {APPLY}
        </Button>
      </Box>
      {voucher && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Typography color='primary.main'>
            {APPLIED_VOUCHER_$(voucher.voucherCode)}
          </Typography>
          <IconButton
            sx={{
              color: 'text.secondary',
              '&.MuiIconButton-root:hover': { bgcolor: 'transparent' },
              py: 0,
            }}
            onClick={deleteVoucher}
          >
            <Delete sx={{ color: 'primary.main', fontSize: 20 }} />
          </IconButton>
        </Box>
      )}
    </Paper>
  );
};

export default Voucher;
