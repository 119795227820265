export const normalizeAddressBookDataForPayload = ({
  data,
  isUpdate = false,
}) => {
  const {
    countryCode,
    street,
    locality = '',
    town,
    county = '',
    postcode,
  } = data.address;
  const { nickname, contactName, email, phoneNumber } = data;

  return {
    countryCode,
    addressLine1: street,
    addressLine2: locality,
    addressLine3: town,
    addressLine4: county,
    postcode,
    nickname,
    contactName,
    email,
    phoneNumber,
    ...(isUpdate && { isDefault: data.isDefault, isBilling: data.isBilling }),
  };
};

// Filtering necessary fields only from the payload
export const normalizeAddressBookDataForCheckboxChangePayload = ({
  countryCode,
  addressLine1,
  addressLine2,
  addressLine3,
  addressLine4,
  postcode,
  nickname,
  contactName,
  email,
  phoneNumber,
}) => ({
  countryCode,
  addressLine1,
  addressLine2,
  addressLine3,
  addressLine4,
  postcode,
  nickname,
  contactName,
  email,
  phoneNumber,
});

export const normalizeAddressBookDataFromResponse = data => ({
  addressBookId: data?.addressBookId,
  isDefault: data?.isDefault,
  isBilling: data?.isBilling,
  address: {
    countryCode: data?.countryCode,
    street: data?.addressLine1,
    locality: data?.addressLine2,
    town: data?.addressLine3,
    county: data?.addressLine4,
    postcode: data?.postcode,
  },
  nickname: data?.nickname,
  contactName: data?.contactName,
  email: data?.email,
  phoneNumber: data?.phoneNumber,
});

export const normalizeFormValuesForValidation = ({ address, ...rest }) => {
  const { contactName, nickname, email, phoneNumber } = rest;

  return {
    address: {
      countryCode: address?.countryCode,
      street: address?.street,
      locality: address?.locality,
      town: address?.town,
      county: address?.county,
      postcode: address?.postcode,
    },
    contactName,
    nickname,
    email,
    phoneNumber,
  };
};

export const sortAddressBooks = (addressBooks = []) => {
  if (addressBooks.length === 0) {
    return addressBooks;
  }

  return addressBooks.sort((a, b) => {
    // First criterion: isDefault
    if (a.isDefault !== b.isDefault) {
      return a.isDefault ? -1 : 1; // Pin true values to the top
    }
    // Second criterion: isBilling
    if (a.isBilling !== b.isBilling) {
      return a.isBilling ? -1 : 1;
    }

    return a.nickname.toLowerCase().localeCompare(b.nickname.toLowerCase());
  });
};
