import { upperFirst } from 'lodash';
import moment from 'moment-timezone';
import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { Add, Delete, Edit, Remove } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { ShipmentPrice } from '@dpdgroupuk/consumer-shipping-helper';
import { useSelector } from 'react-redux';
import BasketCardDetails from '../../../components/BasketCardDetails';
import PickupPointWidget from '../../../components/PickupPointwidget';
import {
  ADD_TO_CHECKOUT,
  CHOSEN_PICKUP_POINT,
  DROP_OFF_POINT,
  EDIT,
  PARCEL_TO_$,
  REMOVE,
  REMOVE_FROM_CHECKOUT,
  SHIPPING_DETAILS,
  TO_BE_COLLECTED,
  TO_BE_DROPPED_OFF,
} from '../../../constants/strings';
import { DATE_TIME_FORMAT } from '../../../constants/dateFormat';
import { getReceiverContactName } from '../../../utils/basketItem';
import ConfigSlice from '../../../redux/configSlice';

const PickupContainer = ({ pickupLocation, label, isMobileView }) =>
  pickupLocation && (
    <Grid
      sx={{
        mt: { xs: 2, md: 0 },
        display: isMobileView
          ? { xs: 'inline-block', md: 'none' }
          : { xs: 'none', md: 'inline-block' },
      }}
      md={6}
    >
      <PickupPointWidget
        pickupPoint={{ pickupLocation }}
        pickupPointLabel={label}
        showAddressInLine
      />
    </Grid>
  );

const BasketCard = ({
  basketItem,
  references,
  onRemove,
  basketLength,
  isInCheckoutList,
  onAddToCheckout,
  onRemoveFromCheckout,
}) => {
  const {
    shipment: { outboundConsignment, shipmentDate, _valid },
  } = basketItem;
  const priceConfig = useSelector(ConfigSlice.selectors.getPriceConfig);

  const receiverContactName = getReceiverContactName(basketItem);
  const collectionDate = shipmentDate
    ? ` ${moment(shipmentDate, DATE_TIME_FORMAT).format('DD MMMM YYYY')}`
    : '';

  const price = ShipmentPrice.calculateShipmentPrice(
    basketItem.shipment,
    references.networkPrice,
    priceConfig
  ).toViewJSON(2);

  const { _dropOffDetails } = outboundConsignment;

  return (
    <>
      <Grid item sx={{ width: '100%' }}>
        <Divider variant='middle' sx={{ textTransform: 'uppercase' }}>
          {_dropOffDetails
            ? TO_BE_DROPPED_OFF
            : `${TO_BE_COLLECTED}${collectionDate}`}
        </Divider>
      </Grid>
      <Grid sx={{ mt: 1 }}>
        <Paper sx={{ mb: 3 }}>
          <Grid sx={{ p: 2 }}>
            <Grid container sx={{ justifyContent: 'space-between' }}>
              <Typography variant='h3'>
                {upperFirst(
                  PARCEL_TO_$(
                    receiverContactName,
                    outboundConsignment.parcels.length
                  )
                )}
              </Typography>
              {price.totalIncVatAmount && (
                <Typography variant='h3' color='primary.main'>
                  {`£${price.totalIncVatAmount}`}
                </Typography>
              )}
            </Grid>
            <Typography variant='body2'>
              {outboundConsignment._networkDesc}
            </Typography>
          </Grid>
          <Divider />
          <Grid container sx={{ p: 2, py: 3 }}>
            <Grid sx={{ mb: { xs: 2, md: 0 } }} xs={12} md={6}>
              <BasketCardDetails
                data={outboundConsignment.collectionDetails}
                title={SHIPPING_DETAILS.SENDER}
              />
              {/* Drop Off point mobile view */}
              <PickupContainer
                pickupLocation={references.collectionPickup}
                label={DROP_OFF_POINT}
                isMobileView
              />
            </Grid>
            <Grid xs={12} md={6}>
              <BasketCardDetails
                data={outboundConsignment.deliveryDetails}
                title={SHIPPING_DETAILS.RECEIVER}
              />
              {/* Pickup point mobile view */}
              <PickupContainer
                pickupLocation={references.deliveryPickup}
                label={CHOSEN_PICKUP_POINT}
                isMobileView
              />
            </Grid>
          </Grid>
          {/* Pickup points web view */}
          <Grid
            container
            sx={{ p: 2, pt: 0, display: { xs: 'none', md: 'block' } }}
            md={12}
          >
            <PickupContainer
              pickupLocation={references.collectionPickup}
              label={DROP_OFF_POINT}
            />
            <PickupContainer
              pickupLocation={references.deliveryPickup}
              label={CHOSEN_PICKUP_POINT}
            />
          </Grid>
          <Divider />
          <Grid container sx={{ p: 2 }} xs={12}>
            <Grid item display='flex' flexGrow={1} justifyContent='center'>
              <Button
                startIcon={<Edit sx={{ color: 'text.secondary' }} />}
                href={`/order?basketItemId=${basketItem.basketItemId}`}
                sx={{ color: 'text.secondary' }}
              >
                {EDIT}
              </Button>
            </Grid>
            <Grid item display='flex' flexGrow={1} justifyContent='center'>
              <Button
                startIcon={<Delete sx={{ color: 'text.secondary' }} />}
                onClick={onRemove}
                sx={{ color: 'text.secondary' }}
              >
                {REMOVE}
              </Button>
            </Grid>
            {basketLength > 1 && _valid && (
              <Grid
                item
                justifyContent='flex-end'
                sx={{
                  flexGrow: { xs: 1, md: 0 },
                  marginTop: { xs: 1, md: 0 },
                }}
              >
                <Button
                  variant={isInCheckoutList ? 'contained' : 'outlined'}
                  onClick={() =>
                    isInCheckoutList
                      ? onRemoveFromCheckout(basketItem.basketItemId)
                      : onAddToCheckout(basketItem.basketItemId)
                  }
                  startIcon={isInCheckoutList ? <Remove /> : <Add />}
                  sx={{ width: { xs: '100%', md: '240px' } }}
                >
                  {isInCheckoutList ? REMOVE_FROM_CHECKOUT : ADD_TO_CHECKOUT}
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

BasketCard.propTypes = {
  references: PropTypes.shape({
    collectionPickup: PropTypes.object,
    deliveryPickup: PropTypes.object,
    networkPrice: PropTypes.object,
  }),
  basketItem: PropTypes.shape({
    basketItemId: PropTypes.string,
    shipment: PropTypes.object,
  }),
  onRemove: PropTypes.func,
  basketLength: PropTypes.number,
  isInCheckoutList: PropTypes.bool,
  onAddToCheckout: PropTypes.func,
  onRemoveFromCheckout: PropTypes.func,
};

export default BasketCard;
