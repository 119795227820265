import { createSelector } from '@reduxjs/toolkit';
import {
  ShipmentPrice,
  OrderPriceAccumulator,
} from '@dpdgroupuk/consumer-shipping-helper';
import configSlice from '../../redux/configSlice';
import { OrderSliceSelectors } from './slice';

export const getTotalAmount = createSelector(
  [
    OrderSliceSelectors.getReferences,
    OrderSliceSelectors.getValues,
    configSlice.selectors.getPriceConfig,
  ],
  (references, shipment, config) => {
    const network = references.networks.find(
      n => n.networkKey === shipment.outboundConsignment.networkCode
    );

    if (!network) {
      return new ShipmentPrice().toViewJSON(2);
    }
    const accumulator = OrderPriceAccumulator.aggregate(
      new OrderPriceAccumulator(),
      'new',
      ShipmentPrice.calculateShipmentPrice(shipment, network.price, config)
    );
    if (references.voucher) {
      accumulator.applyVoucherDiscount(references.voucher);
    }
    return accumulator.toViewJSON(2);
  }
);
