import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Box, IconButton, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Instagram } from '@mui/icons-material';
import { GeopostLogo, Linkedin } from '../icons';
import {
  ALL_RIGHTS_RESERVED,
  COOKIE_POLICY,
  CYBER_SECURITY,
  DPD_GROUP_LTD,
  DPD_REGISTERED_IN,
  DPD_REGISTERED_OFFICE,
  FUEL_ENERGY_SURCHARGE,
  GEOPOST,
  POLICY_FOR_DISABLED_CONSUMERS,
  PRIVACY_NOTICE,
  SOCIAL_MEDIA,
  TERMS_AND_CONDITIONS,
} from '../../constants/strings';
import {
  PRIVACY_POLICY_URL,
  COOKIE_POLICY_URL,
  CYBER_SECURITY_URL,
  POLICY_FOR_DISABLED_URL,
  FUEL_ENERGY_SURCHARGE_URL,
  GEOPOST_URL,
  TERMS_AND_CONDITIONS_URL,
} from '../../constants/externalLinks';
import {
  DONT_HAVE_ACCESS,
  NOT_FOUND,
  PICKUP_FINDER,
  PROFILE,
} from '../../constants/routes';
import { USER_PREFERENCES_MOBILE_MENU_FIXED_HEIGHT } from '../../constants/gaps';
import { GENERAL_SOCIAL_LINKS } from '../../constants/socialsLinks';

import useBreakpoint from '../../hooks/useBreakpoint';

const StyledBox = styled(Box)({
  backgroundColor: '#CAC4BE',
  color: 'black',
});

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '0.75rem',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  borderRight: `1px solid ${theme.palette.text.primary}`,
  textWrap: 'nowrap',
}));

const StyledIconButton = styled(IconButton)({
  padding: 0,
});

const forbiddenPages = [PICKUP_FINDER, PROFILE, NOT_FOUND, DONT_HAVE_ACCESS];

export default function Footer() {
  const { pathname: currentPath } = useLocation();
  const isSmallScreen = useBreakpoint('md');
  const hasBottomGap = currentPath.includes(PROFILE) && isSmallScreen;
  const isFooterUnavailable = forbiddenPages.some(page =>
    currentPath.includes(page)
  );

  return (
    <Box sx={{ display: isFooterUnavailable ? 'none' : 'block' }}>
      <StyledBox
        sx={{
          paddingTop: 4,
          paddingRight: 4,
          paddingLeft: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            borderBottom: '1px solid black',
            pb: '0.4375rem',
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='body2' color='text.secondary'>
              {'© '}
              {new Date().getFullYear()}
              {DPD_GROUP_LTD}
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              {ALL_RIGHTS_RESERVED}
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              {DPD_REGISTERED_IN}
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              {DPD_REGISTERED_OFFICE}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5625rem',
              alignItems: { md: 'flex-end' },
              pt: { xs: 4, md: 0 },
            }}
          >
            <Typography
              variant='body2'
              color='text.secondary'
              sx={{
                textAlign: 'end',
              }}
            >
              {SOCIAL_MEDIA}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '1.375rem',
              }}
            >
              <StyledIconButton
                href={GENERAL_SOCIAL_LINKS.LINKEDIN}
                target='_blank'
                disableRipple
              >
                <Linkedin />
              </StyledIconButton>
              <StyledIconButton
                href={GENERAL_SOCIAL_LINKS.INSTAGRAM}
                target='_blank'
                disableRipple
              >
                <Instagram color='primary' fontSize='1rem' />
              </StyledIconButton>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <GeopostLogo />
          </Box>
        </Box>
      </StyledBox>
      <StyledBox
        sx={{
          paddingBottom: 4,
          paddingLeft: theme => `calc(${theme.spacing(4)} - 0.5rem)`,
          paddingRight: theme => `calc(${theme.spacing(4)} - 0.5rem)`,
          mb: hasBottomGap ? USER_PREFERENCES_MOBILE_MENU_FIXED_HEIGHT : 0,
        }}
      >
        <Box sx={{ pt: { xs: 1, md: 0 }, display: 'flex', flexWrap: 'wrap' }}>
          <StyledLink
            href={TERMS_AND_CONDITIONS_URL}
            target='_blank'
            rel='noopener'
            underline='none'
          >
            {TERMS_AND_CONDITIONS}
          </StyledLink>
          <StyledLink
            href={PRIVACY_POLICY_URL}
            target='_blank'
            rel='noopener'
            underline='none'
          >
            {PRIVACY_NOTICE}
          </StyledLink>
          <StyledLink
            href={COOKIE_POLICY_URL}
            target='_blank'
            rel='noopener'
            underline='none'
          >
            {COOKIE_POLICY}
          </StyledLink>
          <StyledLink
            href={CYBER_SECURITY_URL}
            target='_blank'
            rel='noopener'
            underline='none'
          >
            {CYBER_SECURITY}
          </StyledLink>
          <StyledLink
            href={POLICY_FOR_DISABLED_URL}
            target='_blank'
            rel='noopener'
            underline='none'
          >
            {POLICY_FOR_DISABLED_CONSUMERS}
          </StyledLink>
          <StyledLink
            href={FUEL_ENERGY_SURCHARGE_URL}
            target='_blank'
            rel='noopener'
            underline='none'
          >
            {FUEL_ENERGY_SURCHARGE}
          </StyledLink>
          <StyledLink
            href={GEOPOST_URL}
            target='_blank'
            rel='noopener'
            underline='none'
            sx={{ border: 'none' }}
          >
            {GEOPOST}
          </StyledLink>
        </Box>
      </StyledBox>
    </Box>
  );
}
