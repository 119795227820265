import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Portal,
  Typography,
  styled,
} from '@mui/material';
import { ArrowDropUp } from '@mui/icons-material';

import { SUMMARY_DETAILS } from '../../constants/strings';
import TotalPrice from './components/TotalPrice';
import CheckoutList from './components/CheckoutList';

const StyledAccordionSummary = styled(AccordionSummary)({
  flexDirection: 'row-reverse',
  justifyContent: 'flex-end',
  '& .MuiAccordionSummary-content': {
    justifyContent: 'flex-end',
  },
});

const SummaryDetails = ({ price, basketItemsWithPrice }) => (
  <>
    <Paper sx={{ p: 2, mb: 2, display: { xs: 'none', md: 'block' } }}>
      <Typography variant='h3'>{SUMMARY_DETAILS.TITLE}</Typography>
      <CheckoutList items={basketItemsWithPrice} />
      <TotalPrice price={price} />
    </Paper>
    <Portal>
      <Box
        sx={{
          display: { md: 'none' },
          position: 'sticky',
          bottom: 0,
          zIndex: 3,
          width: '100%',
        }}
      >
        <Accordion elevation={4}>
          <StyledAccordionSummary
            expandIcon={<ArrowDropUp />}
            sx={{
              flexDirection: 'row-reverse',
              justifyContent: 'flex-end',
              p: 1,
            }}
          >
            <TotalPrice price={price} />
          </StyledAccordionSummary>
          <AccordionDetails>
            <Typography variant='h3'>{SUMMARY_DETAILS.TITLE}</Typography>
            <CheckoutList items={basketItemsWithPrice} />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Portal>
  </>
);

export default SummaryDetails;
