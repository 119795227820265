import { SHIPMENT_FIELDS } from '../../../../constants/forms';

const baseDependentFields = [
  SHIPMENT_FIELDS.DELIVER_TO.KEY,
  SHIPMENT_FIELDS.PICKUP_DETAILS.KEY,
  SHIPMENT_FIELDS.COLLECT_FROM.KEY,
  SHIPMENT_FIELDS.SHIPMENT_DATE.KEY,
  SHIPMENT_FIELDS.LIABILITY_VALUE.KEY,
  SHIPMENT_FIELDS.LIABILITY.KEY,
  SHIPMENT_FIELDS.NETWORK_CODE.KEY,
  SHIPMENT_FIELDS.NETWORK_DESC.KEY,
  SHIPMENT_FIELDS.DROP_OFF_DETAILS.KEY,
];
// TODO: To be updated
export const fieldDependencyMap = {
  [SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY]: baseDependentFields,
  [SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY]: baseDependentFields,
  [SHIPMENT_FIELDS.COLLECTION_CITY.KEY]: baseDependentFields,
  [SHIPMENT_FIELDS.COLLECTION_STREET.KEY]: baseDependentFields,
  [SHIPMENT_FIELDS.COLLECTION_ADDRESS_KEY.KEY]: baseDependentFields,
  [SHIPMENT_FIELDS.COLLECTION_ADDRESS_BOOK_ID.KEY]: baseDependentFields,
  [SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY]: baseDependentFields,
  [SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY]: baseDependentFields,
  [SHIPMENT_FIELDS.DESTINATION_CITY.KEY]: baseDependentFields,
  [SHIPMENT_FIELDS.DESTINATION_STREET.KEY]: baseDependentFields,
  [SHIPMENT_FIELDS.DESTINATION_ADDRESS_KEY.KEY]: baseDependentFields,
  [SHIPMENT_FIELDS.DELIVERY_ADDRESS_BOOK_ID.KEY]: baseDependentFields,
  [SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY]: baseDependentFields,
  [SHIPMENT_FIELDS.DELIVER_TO.KEY]: [
    SHIPMENT_FIELDS.PICKUP_DETAILS.KEY,
    SHIPMENT_FIELDS.COLLECT_FROM.KEY,
    SHIPMENT_FIELDS.SHIPMENT_DATE.KEY,
    SHIPMENT_FIELDS.LIABILITY_VALUE.KEY,
    SHIPMENT_FIELDS.LIABILITY.KEY,
    SHIPMENT_FIELDS.NETWORK_CODE.KEY,
    SHIPMENT_FIELDS.NETWORK_DESC.KEY,
  ],
  [SHIPMENT_FIELDS.COLLECT_FROM.KEY]: [
    SHIPMENT_FIELDS.DROP_OFF_DETAILS.KEY,
    SHIPMENT_FIELDS.LIABILITY_VALUE.KEY,
    SHIPMENT_FIELDS.LIABILITY.KEY,
    SHIPMENT_FIELDS.NETWORK_CODE.KEY,
    SHIPMENT_FIELDS.NETWORK_DESC.KEY,
  ],
};
