import { round } from 'lodash';
import { Box, Typography } from '@mui/material';

import { SUMMARY_DETAILS, DEFAULT_PRICE } from '../../../constants/strings';

const TotalPrice = ({ price = {} }) => {
  const { totalIncVatAmount, taxAmount } = price;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'space-between',
          marginTop: 2,
        }}
      >
        <Typography
          variant='h3'
          component='span'
          color='primary.main'
          sx={{ mr: 1 }}
        >
          {SUMMARY_DETAILS.TOTAL}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
          xs={12}
        >
          <Typography variant='h3' color='primary.main'>
            {round(totalIncVatAmount, 2) || DEFAULT_PRICE}
          </Typography>
          <Typography variant='body2' component='span' color='text.secondary'>
            {SUMMARY_DETAILS.INCLUDES_VAT_$(
              round(taxAmount, 2) || DEFAULT_PRICE
            )}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default TotalPrice;
