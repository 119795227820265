import Joi from 'joi';
import {
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  CITY,
  CONTACT_NAME,
  COUNTRY,
  COUNTY,
  EMAIL,
  ORGANISATION,
  PHONE_NUMBER,
  POSTAL_ZIP_CODE,
  QUANTITY,
  FIELDS,
} from '../../../constants/forms';
import { POINT_TYPE, REGEX } from '../../../constants';
import { CUSTOM_VALIDATION_ERROR_MESSAGES } from '../../../constants/strings';
import { bfpoValidation } from '../../../utils/validators';
import { USER_TYPE } from '../constants';
import { parcelSchema } from './parcel';
import {
  COMMON_PHONE_NUMBER_REGEXP,
  EMAIL_REGEX,
} from '../../../constants/regex';

const empty = [null, ''];

const numberOfParcels = Joi.number().min(1).max(99).label(QUANTITY);
const totalWeight = Joi.number().greater(0);

const addressSchema = {
  countryCode: Joi.string().required().label(COUNTRY),
  postcode: Joi.string()
    .max(8)
    .regex(REGEX.GB_POSTCODE_PATTERN)
    .required()
    .label(POSTAL_ZIP_CODE)
    .custom(bfpoValidation)
    .messages(CUSTOM_VALIDATION_ERROR_MESSAGES),
  street: Joi.string().max(35).required().label(ADDRESS_LINE_1),
  town: Joi.string().max(35).required().label(CITY),
  locality: Joi.string().max(35).allow(null, '').label(ADDRESS_LINE_2),
  county: Joi.string().max(35).allow(null, '').label(COUNTY),
};

export const addressDetailsSchema = Joi.object({
  [FIELDS.ADDRESS_KEY.KEY]: Joi.string().allow(null, ''),
  ...addressSchema,
  organisation: Joi.string().max(35).allow(null, '').label(ORGANISATION),
  locality: Joi.string().max(35).allow(null, '').label(ADDRESS_LINE_2),
}).unknown();

export const contactDetails = () =>
  Joi.object({
    contactName: Joi.string().max(35).allow(null, '').label(CONTACT_NAME),
    telephone: Joi.string()
      .pattern(COMMON_PHONE_NUMBER_REGEXP)
      .max(15)
      .allow(null, '')
      .messages({
        'string.pattern.base': 'Please enter a valid phone number',
      })
      .label(PHONE_NUMBER),
    email: Joi.string()
      .pattern(EMAIL_REGEX)
      .max(100)
      .allow(null, '')
      .messages({
        'string.pattern.base': 'Please enter a valid email',
      })
      .label(EMAIL),
  });

export const email = () =>
  Joi.string().max(100).regex(REGEX.EMAIL_REGEX).label(EMAIL);

export const notificationDetails = () =>
  Joi.object({
    mobile: Joi.string()
      .pattern(COMMON_PHONE_NUMBER_REGEXP)
      .max(15)
      .allow(null, '')
      .messages({
        'string.pattern.base': 'Please enter a valid phone number',
      })
      .label(PHONE_NUMBER),
    email: Joi.string()
      .pattern(EMAIL_REGEX)
      .max(100)
      .allow(null, '')
      .messages({
        'string.pattern.base': 'Please enter a valid email',
      })
      .label(EMAIL),
  });

export const pointType = () =>
  Joi.string().valid(POINT_TYPE.SHOP, POINT_TYPE.DOOR);

export const shipmentUserType = () =>
  Joi.string().valid(USER_TYPE.INDIVIDUAL, USER_TYPE.COMPANY);

export const shipmentSchema = () =>
  Joi.object({
    shipmentDate: Joi.string().allow(null),
    outboundConsignment: Joi.object({
      deliveryDetails: {
        address: addressDetailsSchema,
        contactDetails: contactDetails()
          .keys({
            _addressBookId: Joi.string().allow(null, ''),
            email: email().allow(null, ''),
          })
          .allow(null),
        notificationDetails: notificationDetails().allow(null),
        _deliverTo: pointType(),
        _userType: shipmentUserType().allow(null),
      },

      collectionDetails: {
        address: addressDetailsSchema,
        contactDetails: contactDetails()
          .keys({
            _addressBookId: Joi.string().allow(null, ''),
          })
          .allow(null),
        dropAtShop: Joi.boolean().allow(null),
        notificationDetails: notificationDetails().allow(null),
        _collectFrom: pointType(),
        _userType: shipmentUserType().allow(null),
      },

      pickupDetails: Joi.object({
        pickupLocationCode: Joi.string().max(20),
        pickupCountryCode: Joi.string().max(2).regex(REGEX.COUNTRY_CODE),
        pickupOrganisation: Joi.string()
          .max(35)
          .allow(...empty),
        pickupPostcode: Joi.string().allow(...empty),
        pickupAddressLine1: Joi.string()
          .max(35)
          .allow(...empty),
        pickupAddressLine2: Joi.string()
          .max(35)
          .allow(...empty),
        pickupAddressLine3: Joi.string()
          .max(35)
          .allow(...empty),
        pickupAddressLine4: Joi.string()
          .max(35)
          .allow(...empty),
        allowRemotePickup: Joi.boolean().allow(null),
      }).allow(null),

      _dropOffDetails: Joi.object({
        pickupLocationCode: Joi.string().max(20),
        pickupCountryCode: Joi.string().max(2).regex(REGEX.COUNTRY_CODE),
        pickupOrganisation: Joi.string()
          .max(35)
          .allow(...empty),
        pickupPostcode: Joi.string().allow(...empty),
        pickupAddressLine1: Joi.string()
          .max(35)
          .allow(...empty),
        pickupAddressLine2: Joi.string()
          .max(35)
          .allow(...empty),
        pickupAddressLine3: Joi.string()
          .max(35)
          .allow(...empty),
        pickupAddressLine4: Joi.string()
          .max(35)
          .allow(...empty),
      }).allow(null),
      numberOfParcels,
      totalWeight,
      parcelDescription: Joi.string().max(45).allow('', null),
      networkCode: Joi.string(),
      liability: Joi.boolean(),
      liabilityValue: Joi.number(),
      parcels: Joi.array().items(parcelSchema()),
      // shippingRef1: Joi.string().max(25),
    }),
    invoice: Joi.object().allow(null),
    _valid: Joi.boolean().allow(null),
  });
