import * as POINT_TYPE from './pointTypes';

export const ORGANISATION = 'Organisation';
export const ADDRESS_LINE_1 = 'Address Line 1';
export const ADDRESS_LINE_2 = 'Address Line 2';
export const CITY = 'City';
export const COUNTY = 'County';
export const POSTAL_ZIP_CODE = 'Postal/Zip code';
export const COUNTRY = 'Country';
export const QUANTITY = 'Quantity';
export const WEIGHT = 'Weight (kg)';
export const HEIGHT = 'Height (cm)';
export const WIDTH = 'Width (cm)';
export const LENGTH = 'Length (cm)';
export const CONTACT_NAME = 'Contact Name';
export const NICKNAME = 'Nickname';
export const EMAIL = 'Email';
export const PHONE_NUMBER = 'Phone Number';

export const FIELDS = {
  DESTINATION_COUNTRY: {
    KEY: 'destinationCountry',
    LABEL: 'Destination Country',
  },
  ADDRESS_KEY: {
    KEY: 'addressId',
    LABEL: 'City or Postcode',
  },
  POSTCODE: {
    KEY: 'postcode',
    LABEL: 'Start typing an address',
    PLACEHOLDER: 'Start typing an address',
  },
  COUNTRY: {
    KEY: 'country',
    LABEL: COUNTRY,
  },
  COMMODITY: {
    KEY: 'commodity',
    LABEL: 'Search term',
    PLACEHOLDER: 'Search term',
  },
  ADDRESS: {
    KEY: 'address',
    LABEL: 'Start typing your address',
  },
  ADDRESS_COORDINATES: {
    KEY: 'addressCoordinates',
    LABEL: 'Coordinates',
  },
  EMAIL: {
    KEY: 'email',
    LABEL: 'Email Address',
    PLACEHOLDER: 'Enter your email address',
  },
};

export const COLLECTION_DETAILS_KEY = 'outboundConsignment.collectionDetails';
export const COLLECTION_ADDRESS_KEY = `${COLLECTION_DETAILS_KEY}.address`;
export const DESTINATION_ADDRESS_KEY =
  'outboundConsignment.deliveryDetails.address';

export const SHIPMENT_FIELDS = {
  DESTINATION_ADDRESS: {
    KEY: DESTINATION_ADDRESS_KEY,
  },
  DESTINATION_COUNTRY: {
    KEY: `${DESTINATION_ADDRESS_KEY}.countryCode`,
    LABEL: 'Destination Country',
  },
  DESTINATION_ADDRESS_KEY: {
    KEY: `${DESTINATION_ADDRESS_KEY}.${FIELDS.ADDRESS_KEY.KEY}`,
    LABEL: 'Destination Address Key',
  },
  DESTINATION_POSTCODE: {
    KEY: `${DESTINATION_ADDRESS_KEY}.postcode`,
    LABEL: POSTAL_ZIP_CODE,
  },
  DESTINATION_COUNTY: {
    KEY: `${DESTINATION_ADDRESS_KEY}.county`,
    LABEL: COUNTY,
  },
  DESTINATION_CITY: {
    KEY: `${DESTINATION_ADDRESS_KEY}.town`,
    LABEL: CITY,
  },
  DESTINATION_ORGANISATION: {
    KEY: `${DESTINATION_ADDRESS_KEY}.organisation`,
    LABEL: ORGANISATION,
  },
  DESTINATION_STREET: {
    KEY: `${DESTINATION_ADDRESS_KEY}.street`,
    LABEL: ADDRESS_LINE_1,
  },
  DESTINATION_LOCALITY: {
    KEY: `${DESTINATION_ADDRESS_KEY}.locality`,
    LABEL: ADDRESS_LINE_2,
  },
  COLLECTION_DETAILS: {
    KEY: COLLECTION_DETAILS_KEY,
  },
  COLLECTION_ADDRESS: {
    KEY: COLLECTION_ADDRESS_KEY,
  },
  COLLECTION_COUNTRY: {
    KEY: `${COLLECTION_ADDRESS_KEY}.countryCode`,
    LABEL: COUNTRY,
  },
  COLLECTION_ADDRESS_KEY: {
    KEY: `${COLLECTION_ADDRESS_KEY}.${FIELDS.ADDRESS_KEY.KEY}`,
    LABEL: 'Address Key',
  },
  COLLECTION_POSTCODE: {
    KEY: `${COLLECTION_ADDRESS_KEY}.postcode`,
    LABEL: POSTAL_ZIP_CODE,
  },
  COLLECTION_CITY: {
    KEY: `${COLLECTION_ADDRESS_KEY}.town`,
    LABEL: CITY,
  },
  COLLECTION_COUNTY: {
    KEY: `${COLLECTION_ADDRESS_KEY}.county`,
    LABEL: COUNTY,
  },
  COLLECTION_ORGANISATION: {
    KEY: `${COLLECTION_ADDRESS_KEY}.organisation`,
    LABEL: ORGANISATION,
  },
  COLLECTION_STREET: {
    KEY: `${COLLECTION_ADDRESS_KEY}.street`,
    LABEL: ADDRESS_LINE_1,
  },
  COLLECTION_LOCALITY: {
    KEY: `${COLLECTION_ADDRESS_KEY}.locality`,
    LABEL: ADDRESS_LINE_2,
  },
  TOTAL_WEIGHT: {
    KEY: 'outboundConsignment.totalWeight',
  },
  NUMBER_OF_PARCELS: {
    KEY: 'outboundConsignment.numberOfParcels',
  },
  PICKUP_DETAILS: {
    KEY: 'outboundConsignment.pickupDetails',
  },
  PICKUP_LOCATION_CODE: {
    KEY: 'outboundConsignment.pickupDetails.pickupLocationCode',
  },
  DROP_AT_SHOP: {
    KEY: 'outboundConsignment.collectionDetails.dropAtShop',
  },
  LIABILITY: {
    KEY: 'outboundConsignment.liability',
  },
  LIABILITY_VALUE: {
    KEY: 'outboundConsignment.liabilityValue',
  },
  DELIVERY_DESCRIPTION: {
    KEY: 'deliveryDescription',
    LABEL: 'Parcel description (optional)',
  },
  NETWORK_CODE: {
    KEY: 'outboundConsignment.networkCode',
  },
  NETWORK_DESC: {
    KEY: 'outboundConsignment._networkDesc',
  },
  NETWORK_BUS_ZONE: {
    KEY: 'outboundConsignment._networkBusZone',
  },
  SHIPMENT_DATE: {
    KEY: 'shipmentDate',
    LABEL: 'Select a collection date',
  },
  PARCELS: {
    KEY: 'outboundConsignment.parcels',
  },
  PARCEL_HEIGHT: {
    KEY: index => `outboundConsignment.parcels[${index}]._height`,
    LABEL: HEIGHT,
  },
  PARCEL_WIDTH: {
    KEY: index => `outboundConsignment.parcels[${index}]._width`,
    LABEL: WIDTH,
  },
  PARCEL_LENGTH: {
    KEY: index => `outboundConsignment.parcels[${index}]._length`,
    LABEL: LENGTH,
  },
  PARCEL_WEIGHT: {
    KEY: index => `outboundConsignment.parcels[${index}]._weight`,
    LABEL: WEIGHT,
  },
  PARCEL_QUANTITY: {
    KEY: index => `outboundConsignment.parcels[${index}]._quantity`,
    LABEL: QUANTITY,
  },
  PARCEL_DESCRIPTION: {
    KEY: index => `outboundConsignment.parcels[${index}]._description`,
    LABEL: 'Parcel description (optional)',
  },
  DROP_OFF_DETAILS: {
    KEY: 'outboundConsignment._dropOffDetails',
  },
  DROP_OFF_PICKUP_LOCATION_CODE: {
    KEY: 'outboundConsignment._dropOffDetails.pickupLocationCode',
  },
  COLLECT_FROM: {
    KEY: 'outboundConsignment.collectionDetails._collectFrom',
  },
  DELIVER_TO: {
    KEY: 'outboundConsignment.deliveryDetails._deliverTo',
    VALUES: {
      PICKUP: POINT_TYPE.SHOP,
      HOME: POINT_TYPE.DOOR,
    },
  },

  // address details fields
  SENDER_USER_TYPE: {
    KEY: 'outboundConsignment.collectionDetails._userType',
  },
  RECEIVER_USER_TYPE: {
    KEY: 'outboundConsignment.deliveryDetails._userType',
  },
  COLLECTION_ADDRESS_BOOK_ID: {
    KEY: 'outboundConsignment.collectionDetails.contactDetails._addressBookId',
  },
  COLLECTION_CONTACT_NAME: {
    KEY: 'outboundConsignment.collectionDetails.contactDetails.contactName',
  },
  COLLECTION_CONTACT_EMAIL: {
    KEY: 'outboundConsignment.collectionDetails.contactDetails.email',
  },
  COLLECTION_CONTACT_TELEPHONE: {
    KEY: 'outboundConsignment.collectionDetails.contactDetails.telephone',
  },
  DELIVERY_DETAILS: {
    KEY: 'outboundConsignment.deliveryDetails',
  },
  DELIVERY_ADDRESS_BOOK_ID: {
    KEY: 'outboundConsignment.deliveryDetails.contactDetails._addressBookId',
  },
  DELIVERY_CONTACT_NAME: {
    KEY: 'outboundConsignment.deliveryDetails.contactDetails.contactName',
  },
  DELIVERY_CONTACT_EMAIL: {
    KEY: 'outboundConsignment.deliveryDetails.notificationDetails.email',
  },
  DELIVERY_CONTACT_MOBILE: {
    KEY: 'outboundConsignment.deliveryDetails.notificationDetails.mobile',
  },
};

export const ADDRESS_BOOK_FIELDS = {
  COUNTRY: {
    KEY: 'address.countryCode',
    LABEL: COUNTRY,
  },
  ADDRESS: {
    KEY: 'address.street',
    LABEL: ADDRESS_LINE_1,
  },
  LOCALITY: {
    KEY: 'address.locality',
    LABEL: 'Locality',
  },
  CITY: {
    KEY: 'address.town',
    LABEL: CITY,
  },
  COUNTY: {
    KEY: 'address.county',
    LABEL: COUNTY,
  },
  POSTCODE: {
    KEY: 'address.postcode',
    LABEL: 'Postcode',
  },
  CONTACT_NAME: {
    LABEL: CONTACT_NAME,
  },
  NICKNAME: {
    LABEL: NICKNAME,
  },
  EMAIL: {
    LABEL: EMAIL,
  },
  PHONE_NUMBER: {
    LABEL: PHONE_NUMBER,
  },
};

export const ACCOUNT_DETAILS_FIELDS = {
  COMMUNICATION_PREFERENCES: {
    SMS: {
      NAME: 'notifyBySms',
      LABEL: 'SMS',
    },
    EMAIL: {
      NAME: 'notifyByEmail',
      LABEL: 'Email',
    },
    UPDATE: {
      NAME: 'allowTrackingUpdates',
      LABEL: 'Parcel delivery notifications',
    },
  },
  ACCOUNT_DETAILS: {
    FIRST_NAME: {
      NAME: 'firstName',
      LABEL: 'First Name',
    },
    LAST_NAME: {
      NAME: 'lastName',
      LABEL: 'Last Name',
    },
    EMAIL_ADDRESS: {
      NAME: 'email',
      LABEL: 'Email Address',
    },
    PHONE_NUMBER: {
      NAME: 'phone',
      LABEL: 'Phone Number',
    },
    COMPANY_NAME: {
      NAME: 'companyName',
      LABEL: 'Company Name',
    },
    VAT_NUMBER: {
      NAME: 'VATNumber',
      LABEL: 'VAT Number',
    },
  },
};
