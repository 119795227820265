import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { addressBookApis } from '../apis';
import {
  normalizeAddressBookDataFromResponse,
  sortAddressBooks,
} from '../utils/addressBook';
import { ADDRESS_BOOK_INITIAL_FORM_VALUES } from '../constants/addressBook';
import { DEFAULT_PAGINATION_SEARCH_OVERVIEW_SIZE } from '../constants/pagination';

const initialState = {
  billingAddress: null,
  defaultAddress: null,
  data: [],
  selectedAddressBook: ADDRESS_BOOK_INITIAL_FORM_VALUES,
};

const defaultFetchAddressBooksParams = {
  searchPage: 1,
  searchPageSize: DEFAULT_PAGINATION_SEARCH_OVERVIEW_SIZE,
};

const fetchAddressBooks = createAsyncThunk(
  'addressBook/filter',
  async ({ searchPage, searchPageSize } = defaultFetchAddressBooksParams) => {
    const response = await addressBookApis.getAddressBooks({
      searchPage,
      searchPageSize,
    });
    return response.data;
  }
);

const getAddressBook = createAsyncThunk(
  'addressBook/get',
  async addressBookId => {
    try {
      const { data } = await addressBookApis.getAddressBook(addressBookId);
      return data;
    } catch (error) {
      if (error.response) {
        throw error.response?.data;
      } else {
        throw error;
      }
    }
  }
);

const fetchDefaultAddressBook = createAsyncThunk(
  'addressBook/fetchDefault',
  async () => {
    const { data } = await addressBookApis.getDefaultAddress();
    return data;
  }
);

const updateAddressBook = createAsyncThunk(
  'addressBook/update',
  async ({ addressBookId, addressBookData }) => {
    try {
      const { data } = await addressBookApis.updateAddressBook({
        addressBookId,
        addressBookData,
      });
      return data;
    } catch (error) {
      if (error.response) {
        throw error.response?.data;
      } else {
        throw error;
      }
    }
  }
);

const deleteAddressBook = createAsyncThunk(
  'addressBook/delete',
  async addressBookId => {
    try {
      const { data } = await addressBookApis.deleteAddressBook(addressBookId);
      return data?.addressBookId;
    } catch (error) {
      if (error.response) {
        throw error.response?.data;
      } else {
        throw error;
      }
    }
  }
);

const createAddressBook = createAsyncThunk(
  'addressBook/create',
  async addressBookData => {
    try {
      const { data } = await addressBookApis.createAddressBook(addressBookData);
      return data;
    } catch (error) {
      if (error.response) {
        throw error.response?.data;
      } else {
        throw error;
      }
    }
  }
);

const slice = createSlice({
  name: 'addressBook',
  initialState,
  reducers: {
    resetSelectedAddressBook(state) {
      state.selectedAddressBook = ADDRESS_BOOK_INITIAL_FORM_VALUES;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAddressBooks.fulfilled, (state, { payload }) => {
        const { addressBooks } = payload;
        state.data = [...addressBooks];
        addressBooks.forEach(ab => {
          if (ab.isDefault) {
            state.defaultAddress = ab;
          }
        });
        return state;
      })
      .addCase(createAddressBook.fulfilled, (state, { payload }) => {
        if (payload.isDefault) {
          state.defaultAddress = payload;
        }
      })
      .addCase(updateAddressBook.fulfilled, (state, { payload }) => {
        const index = findIndex(
          state.data,
          a => a.addressBookId === payload.addressBookId
        );
        if (index !== -1) {
          if (payload.isDefault) {
            state.defaultAddress = payload;
            // reset default value
            state.data.forEach(item => {
              item.isDefault = false;
            });
          }
          if (payload.isBilling) {
            state.billingAddress = payload;
            state.data.forEach(item => {
              item.isBilling = false;
            });
          }
          state.data[index] = payload; // Update the item in the array
        }
      })
      .addCase(deleteAddressBook.fulfilled, (state, { payload }) => {
        if (state.defaultAddress?.addressBookId === payload) {
          state.defaultAddress = null;
        }
      })
      .addCase(getAddressBook.fulfilled, (state, { payload }) => {
        state.selectedAddressBook = {
          ...normalizeAddressBookDataFromResponse(payload),
        };
      })
      .addCase(fetchDefaultAddressBook.fulfilled, (state, { payload }) => {
        state.defaultAddress = payload;
      });
  },
});

const getAddressBooksState = state => state.addressBook;

const getAddressBooks = createSelector([getAddressBooksState], state =>
  sortAddressBooks([...state.data])
);

const getAddressBooksByPage = createSelector([getAddressBooks], data => data);

const getAddressBookLength = createSelector(
  [getAddressBooksState],
  state => state.data.length
);

const getSelectedAddressBook = createSelector(
  [getAddressBooksState],
  addressBook => addressBook.selectedAddressBook
);

const getDefaultAddressBook = state => state.addressBook.defaultAddress;

export default {
  reducer: slice.reducer,
  actions: {
    fetchDefaultAddressBook,
    fetchAddressBooks,
    getAddressBook,
    updateAddressBook,
    deleteAddressBook,
    createAddressBook,
    resetSelectedAddressBook: slice.actions.resetSelectedAddressBook,
  },
  selectors: {
    getAddressBookLength,
    getDefaultAddressBook,
    getSelectedAddressBook,
    getAddressBooksByPage,
  },
};
