import PropTypes from 'prop-types';
import { Box, Typography, useTheme } from '@mui/material';
import AddressView from '../MapBox/components/AddressView';
import PickupPointImage from '../../assets/images/pickup_point.png';
import { getPartnerLogo, getPickupAddress } from '../../utils/pickupPoint';

const PickupPointWidget = ({
  pickupPoint,
  pickupPointLabel,
  showAddressInLine,
}) => {
  const theme = useTheme();
  const partnerLogo = getPartnerLogo(pickupPoint);
  const address = getPickupAddress(pickupPoint);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: theme.spacing(2),
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <Box
        sx={{
          border: `1px solid ${theme.palette.primary.borderLight}`,
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          minWidth: '130px',
          maxWidth: '130px',
        }}
      >
        <img
          src={partnerLogo || PickupPointImage}
          alt='Pickup Point'
          style={{
            height: 'auto',
            minWidth: '100%',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={showAddressInLine && { fontSize: '15px', fontWeight: 'bold' }}
        >
          {pickupPointLabel}
        </Typography>
        <AddressView
          address={address}
          inline={showAddressInLine}
          pickupPoint={pickupPoint}
          titleWidth='100%'
          boxWidth='100%'
        />
      </Box>
    </Box>
  );
};

PickupPointWidget.propTypes = {
  partnerLogo: PropTypes.string,
  pickupPoint: PropTypes.object.isRequired,
  pickupPointLabel: PropTypes.string,
  address: PropTypes.object.isRequired,
};

export default PickupPointWidget;
