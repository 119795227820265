import { useParams } from 'react-router';
import { useCallback } from 'react';
import Loader from '../../features/Loader';
import { shipmentsApis } from '../../apis';
import Error from '../Auth/Error';
import Collection from './Collection';
import DropOff from './DropOff';

const Shipment = () => {
  const { shipmentId } = useParams();

  const loadData = useCallback(
    ({ shipmentId }) => shipmentsApis.getShipmentById(shipmentId),
    []
  );

  return (
    <Loader
      promiseFn={loadData}
      shipmentId={shipmentId}
      errorComponent={error => <Error values={{ error }} />}
    >
      {shipment =>
        shipment.outboundConsignment.collectionDetails.dropAtShop ? (
          <DropOff shipment={shipment} shipmentId={shipmentId} />
        ) : (
          <Collection shipment={shipment} shipmentId={shipmentId} />
        )
      }
    </Loader>
  );
};
export default Shipment;
