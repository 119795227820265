import React from 'react';
import Loader from '../../features/Loader';
import MultiParcel from '../MultiParcel/MultiParcel';
import SingleParcel from '../SingleParcel/SingleParcel';
import NotFoundMessage from '../../pages/NotFound/components/NotFoundMessage';
import { CONFIRMATION } from '../../constants/strings';

const renderContent = (barcodes, shipmentId, shipment, os, isSmallScreen) => {
  const hasNoBarcodes = !barcodes || barcodes.length === 0;
  const isSingleParcel = barcodes?.length === 1;
  const isMultiParcel = barcodes?.length > 1;

  if (hasNoBarcodes) {
    return <NotFoundMessage title={CONFIRMATION.ERROR} />;
  }

  if (isSingleParcel) {
    return (
      <SingleParcel
        barcodes={barcodes}
        shipmentId={shipmentId}
        shipment={shipment}
        os={os}
        isSmallScreen={isSmallScreen}
      />
    );
  }

  if (isMultiParcel) {
    return (
      <MultiParcel
        barcodes={barcodes}
        shipmentId={shipmentId}
        os={os}
        shipment={shipment}
        isSmallScreen={isSmallScreen}
      />
    );
  }
};

const ParcelDisplay = ({
  fetchBarcode,
  shipmentId,
  shipment,
  os,
  isSmallScreen,
}) => {
  return (
    <Loader promiseFn={fetchBarcode}>
      {barcodes =>
        renderContent(barcodes, shipmentId, shipment, os, isSmallScreen)
      }
    </Loader>
  );
};

export default ParcelDisplay;
