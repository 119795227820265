import apis from './apis';

export const fetchCountries = (query, options) =>
  apis.get('references/countries', {
    query,
    options,
  });

export const fetchNetworks = (query, options) =>
  apis.get('references/networks', {
    params: query,
    options,
  });

export const fetchNetworkPrice = ({ busZone, networkCode }, options) =>
  apis.get('references/prices', {
    params: { busZone, networkCode },
    options,
  });
