import { isNil } from 'lodash';
import { createSelector, createStructuredSelector } from 'reselect';

import addressBooksSlice from '../../redux/addressBooksSlice';
import { BasketSelectors } from '../../redux/basket';
import userSlice from '../../redux/userSlice';
import { joinStringsWith } from '../../utils/formatters';
import { FIELDS } from '../../constants/forms';

const defaultEmptyAddress = {
  organisation: '',
  // property: deliveryAddress.property, there is no mapping on server side
  street: '',
  locality: '',
  town: '',
  county: '',
  postcode: '',
  countryCode: '',
  [FIELDS.ADDRESS_KEY.KEY]: '',
};

export const getDeliveryAddress = (
  state,
  { deliveryCountryCode, deliveryAddress }
) =>
  deliveryAddress
    ? {
        organisation: deliveryAddress.organisation,
        // property: deliveryAddress.property, there is no mapping on server side
        street: deliveryAddress.street,
        locality: deliveryAddress.locality,
        town: deliveryAddress.town,
        county: deliveryAddress.county,
        postcode: deliveryAddress.postcode,
        countryCode: deliveryCountryCode,
        [FIELDS.ADDRESS_KEY.KEY]: deliveryAddress.key,
      }
    : defaultEmptyAddress;

export const getDefaultCollectionAddress = createSelector(
  addressBooksSlice.selectors.getDefaultAddressBook,
  defaultAddress =>
    isNil(defaultAddress)
      ? {
          countryCode: 'GB',
        }
      : {
          countryCode: defaultAddress.countryCode,
          postcode: defaultAddress.postcode,
          county: defaultAddress.addressLine4,
          town: defaultAddress.addressLine3,
          // organisation: deliveryAddress.organisation,
          street: defaultAddress.addressLine1,
          locality: defaultAddress.addressLine2,
        }
);

export const getDefaultCollectionContactDetails = createSelector(
  addressBooksSlice.selectors.getDefaultAddressBook,
  userSlice.selectors.getAccountDetailsData,
  (defaultAddress, accountDetails) => {
    if (!isNil(defaultAddress)) {
      return {
        _addressBookId: defaultAddress.addressBookId,
        contactName: defaultAddress.contactName,
        telephone: defaultAddress.phoneNumber,
        email: defaultAddress.email,
      };
    }

    if (!isNil(accountDetails)) {
      return {
        _addressBookId: null,
        contactName: joinStringsWith(
          [accountDetails.firstName, accountDetails.lastName],
          ''
        ),
        telephone: accountDetails.phone,
        email: accountDetails.email,
      };
    }

    return null;
  }
);

export const getShipmentDto = createStructuredSelector({
  'outboundConsignment.deliveryDetails.address': getDeliveryAddress,
  'outboundConsignment.collectionDetails.address': getDefaultCollectionAddress,
  'outboundConsignment.collectionDetails.contactDetails':
    getDefaultCollectionContactDetails,
  'outboundConsignment.parcels': () => [
    {
      parcelId: crypto.randomUUID(),
    },
  ],
  'outboundConsignment.totalWeight': () => 1,
  'outboundConsignment.numberOfParcels': () => 1,
  'outboundConsignment.liabilityValue': () => 50,
  'outboundConsignment.shippingRef1': BasketSelectors.getBasketId,
  _valid: () => false,
});
