import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthSelectors } from '../features/Auth';
import { countriesActions } from './countriesSlice';
import addressBooksSlice from './addressBooksSlice';
import configSlice from './configSlice';
import userSlice from './userSlice';
import { BasketActions } from './basket';

export const loadAppData = createAsyncThunk(
  'app/loadInitialData',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const promises = [
        dispatch(countriesActions.fetchCountries()),
        dispatch(configSlice.actions.fetchConfig('common')),
      ];
      const currentSession = AuthSelectors.getCurrentSession(getState());
      if (currentSession.basketId) {
        promises.push(dispatch(BasketActions.getBasketItems()));
      }
      if (currentSession?.uid) {
        promises.push(dispatch(userSlice.actions.fetchAccountDetails()));
        promises.push(
          dispatch(addressBooksSlice.actions.fetchDefaultAddressBook())
        );
      }
      return await Promise.all(promises);
    } catch (e) {
      if (e.response) {
        return rejectWithValue(e.response.data);
      } else {
        throw e;
      }
    }
  }
);
