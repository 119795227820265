import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

const OrderCard = ({ shipment }) => {
  return <Grid sx={{ mt: 1 }}>put content here</Grid>;
};

OrderCard.propTypes = {
  shipment: PropTypes.object,
};

export default OrderCard;
