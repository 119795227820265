import { Box, Typography } from '@mui/material';

import { DIMENSIONS_$ } from '../../../constants/strings';

const ParcelDetails = ({ parcel, networkDesc, showMoreDetails }) => {
  const parcelDescription = parcel._description || 'Parcel';

  return (
    <Box>
      <Typography variant='body2' color={!showMoreDetails && 'text.secondary'}>
        {(showMoreDetails ? `${parcel._weight} kg ` : '') + parcelDescription}
      </Typography>
      <Typography variant='body2' color='text.secondary'>
        {(showMoreDetails ? '' : `${parcel._weight}KG | ${networkDesc} | `) +
          DIMENSIONS_$(parcel._height, parcel._length, parcel._width)}
      </Typography>
    </Box>
  );
};

const ParcelsDetails = ({
  receiverContactName,
  parcels,
  networkDesc,
  showMoreDetails,
  price,
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <Box>
      {receiverContactName && (
        <Typography variant='body2' sx={{ mb: 1 }}>
          {receiverContactName}
        </Typography>
      )}

      {parcels?.length &&
        parcels.map(parcel => (
          <ParcelDetails
            key={parcel.parcelId}
            parcel={parcel}
            networkDesc={networkDesc}
            showMoreDetails={showMoreDetails}
          />
        ))}
    </Box>
    <Typography variant='body2'>{price}</Typography>
  </Box>
);

export default ParcelsDetails;
