import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';

import { get } from 'lodash';
import { locationsApis, referenceApis } from '../../apis';
import { REFERENCE_NAME } from '../Order/constants';
import { BasketSelectors } from '../../redux/basket';

const initialState = {
  references: {},
  checkoutList: [],
};

const fetchBasketItemReferences = createAsyncThunk(
  'ui/basketView/fetchBasketItemReferences',
  async basketItem => {
    const { shipment } = basketItem;
    const references = {};
    const { _dropOffDetails, pickupDetails, networkCode, _networkBusZone } =
      shipment.outboundConsignment;
    if (pickupDetails?.pickupLocationCode) {
      const { data } = await locationsApis.getPickupLocationsById(
        pickupDetails.pickupLocationCode
      );

      references[REFERENCE_NAME.DELIVERY_PICKUP] = data;
    }

    if (_dropOffDetails?.pickupLocationCode) {
      const { data } = await locationsApis.getPickupLocationsById(
        _dropOffDetails.pickupLocationCode
      );

      references[REFERENCE_NAME.COLLECTION_PICKUP] = data;
    }

    if (networkCode && _networkBusZone) {
      const { data } = await referenceApis.fetchNetworkPrice({
        busZone: _networkBusZone,
        networkCode,
      });
      references.networkPrice = data;
    }
    return [basketItem.basketItemId, references];
  }
);

const fetchBasketReferences = createAsyncThunk(
  'ui/basketView/fetchBasketReferences',
  async (basketId, { dispatch, getState }) => {
    const result = {};
    const basketItems = BasketSelectors.getBasketItems(getState());
    const promises = basketItems.map(async basketItem => {
      const [basketItemId, references] = await dispatch(
        fetchBasketItemReferences(basketItem)
      ).unwrap();
      result[basketItemId] = references;
    });
    await Promise.all(promises);
    return result;
  }
);

const slice = createSlice({
  name: 'basketView',
  initialState,
  reducers: {
    // used in pair with prop isStepValues = false
    setCheckoutList(state, { payload }) {
      state.checkoutList = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      fetchBasketItemReferences.fulfilled,
      (state, { payload }) => {
        const [basketItemId, references] = payload;
        state.references[basketItemId] = references;
      }
    );
  },
});

const getBasketReferences = state => state.basketView.references;

const getCheckoutList = state => state.basketView.checkoutList;

const getFirstCheckoutItem = createSelector(
  [getCheckoutList, BasketSelectors.getBasketItems],
  (checkoutList, basketItems) => {
    const firstCheckoutId = checkoutList[0];
    return basketItems.find(
      basketItem => basketItem.basketItemId === firstCheckoutId
    );
  }
);

export const getBillingAddress = createSelector(
  [getFirstCheckoutItem],
  ({ shipment } = {}) => {
    const collectionLocality = get(
      shipment,
      'outboundConsignment.collectionDetails.address.locality'
    );
    return {
      phoneNumber: get(
        shipment,
        'outboundConsignment.collectionDetails.contactDetails.telephone'
      ),
      streetAddress: get(
        shipment,
        'outboundConsignment.collectionDetails.address.street'
      ),
      extendedAddress: collectionLocality, // ?
      locality: collectionLocality,
      region: get(
        shipment,
        'outboundConsignment.collectionDetails.address.county'
      ),
      postalCode: get(
        shipment,
        'outboundConsignment.collectionDetails.address.postcode'
      ),
      countryCodeAlpha2: get(
        shipment,
        'outboundConsignment.collectionDetails.address.countryCode'
      ),
    };
  }
);

export const getBillingEmail = createSelector(
  [getFirstCheckoutItem],
  ({ shipment } = {}) =>
    get(shipment, 'outboundConsignment.collectionDetails.contactDetails.email')
);

export const getBillingMobilePhone = createSelector(
  [getFirstCheckoutItem],
  ({ shipment } = {}) =>
    get(
      shipment,
      'outboundConsignment.collectionDetails.contactDetails.telephone'
    )
);

export const getThreeDSecureData = createStructuredSelector({
  email: getBillingEmail,
  mobilePhoneNumber: getBillingMobilePhone,
  billingAddress: getBillingAddress,
});

export default {
  reducer: slice.reducer,
  actions: {
    ...slice.actions,
    fetchBasketItemReferences,
    fetchBasketReferences,
  },
  selectors: {
    getThreeDSecureData,
    getCheckoutList,
    getBasketReferences,
  },
};
