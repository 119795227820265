import { Box, Divider, Typography, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { DELETE_ACCOUNT_SECTION } from '../../../../constants/strings';
import userSlice, {
  isAccountDetailsLoading,
} from '../../../../redux/userSlice';
import ConfirmDialog from '../../../../components/modals/ConfirmModal';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar/index';
import { useOverlay } from '../../../../features/Overlay';
import AccountSectionLayout from './AccountSectionLayout';

const DeleteAccount = () => {
  const loading = useSelector(state => isAccountDetailsLoading(state));
  const [openDeletionModal, setOpenDeletionModal] = useState(false);
  const navigate = useNavigate();
  const { showError } = useCustomSnackbar();
  const dispatch = useDispatch();
  const overlay = useOverlay();

  const handleConfirmDeletion = useCallback(async () => {
    setOpenDeletionModal(false);

    try {
      overlay.show();
      await dispatch(userSlice.actions.deleteAccount()).unwrap();

      window.location.reload();
    } catch (e) {
      showError({ message: e.message });
    } finally {
      overlay.hide();
    }
  }, [navigate, showError]);

  return (
    <AccountSectionLayout>
      <Box sx={{ paddingX: 2, paddingTop: 2, paddingBottom: 1 }}>
        <Typography
          variant='typography/subtitle2'
          color='primary'
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '158%',
            letterSpacing: '0.1px',
          }}
        >
          {DELETE_ACCOUNT_SECTION.DELETE_ACCOUNT}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ paddingX: 2, paddingY: 3 }}>
        <Typography
          variant='body1'
          sx={{
            fontFamily: 'Roboto',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px',
            letterSpacing: '0.16px',
            padding: 0,
          }}
        >
          <Typography sx={{ 'padding-bottom': '18px' }} variant='body1'>
            {DELETE_ACCOUNT_SECTION.WE_UNDERSTAND_WARNING}
          </Typography>
          <br />
          <Typography>{DELETE_ACCOUNT_SECTION.ONCE_DELETED_WARNING}</Typography>
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant={'outlined'}
          data-testid={'deleteAccountButton'}
          sx={{
            marginX: 2,
            marginTop: 1,
            marginBottom: 2,
          }}
          disabled={loading}
          onClick={() => setOpenDeletionModal(true)}
        >
          {DELETE_ACCOUNT_SECTION.DELETE_MY_ACCOUNT}
        </Button>
        <ConfirmDialog
          open={openDeletionModal}
          onClose={() => setOpenDeletionModal(false)}
          title={DELETE_ACCOUNT_SECTION.DELETE_ACCOUNT_MODAL.CONFIRM_DELETION}
          confirmButtonText={DELETE_ACCOUNT_SECTION.DELETE_ACCOUNT_MODAL.DELETE}
          onConfirm={handleConfirmDeletion}
          containerStyle={{
            maxWidth: '340px',
          }}
        >
          {DELETE_ACCOUNT_SECTION.DELETE_ACCOUNT_MODAL.DELETION_WARNING}
        </ConfirmDialog>
      </Box>
    </AccountSectionLayout>
  );
};

export default DeleteAccount;
