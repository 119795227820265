import { get } from 'lodash';
import { useCallback } from 'react';

import { useWizard } from '../../../features/Wizard';
import { SHIPMENT_FIELDS, FIELDS } from '../../../constants/forms';
import { locationsApis, addressApis } from '../../../apis';
import { REFERENCE_NAME } from '../constants';
import { Formatters } from '../../../utils';

const loadReferenceAddress = async address => {
  let addressId = address[FIELDS.ADDRESS_KEY.KEY];
  const countryCode = address.countryCode;
  const postcode = address.postcode;
  const property = address.property;
  const street = address.street;

  if (!addressId) {
    // @example: B66 1AA, 100 High Street
    const lookupResult = await addressApis.fastFind({
      lookup: Formatters.joinStringsWithComma([
        postcode,
        Formatters.joinStringsWith([property, street], ' '),
      ]),
      countryISO: countryCode,
    });
    addressId = get(lookupResult, 'Item[0].Key');
  }

  const { Item } = await addressApis.retrieve({
    key: addressId,
    countryISO: countryCode,
  });

  return Item[0];
};

export const useReferencesLoader = () => {
  const { values, references, setReferences } = useWizard();

  const loadDropOffCollection = useCallback(async () => {
    const collectionPickup = references[REFERENCE_NAME.COLLECTION_PICKUP];
    const dropOffPickupLocationCode = get(
      values,
      SHIPMENT_FIELDS.DROP_OFF_PICKUP_LOCATION_CODE.KEY
    );

    if (dropOffPickupLocationCode && !collectionPickup) {
      const collectionPickupResult = await locationsApis.getPickupLocationsById(
        dropOffPickupLocationCode
      );

      setReferences(
        REFERENCE_NAME.COLLECTION_PICKUP,
        collectionPickupResult.data
      );
    }
  }, [references.collectionPickup, setReferences, values]);

  const loadDeliveryPickup = useCallback(async () => {
    const deliveryPickup = references[REFERENCE_NAME.DELIVERY_PICKUP];
    const pickupLocationCode = get(
      values,
      SHIPMENT_FIELDS.PICKUP_LOCATION_CODE.KEY
    );

    if (pickupLocationCode && !deliveryPickup) {
      const deliveryPickupResult = await locationsApis.getPickupLocationsById(
        pickupLocationCode
      );
      setReferences(REFERENCE_NAME.DELIVERY_PICKUP, deliveryPickupResult.data);
    }
  }, [references.deliveryPickup, setReferences, values]);

  const loadDeliveryAddress = useCallback(async () => {
    const deliveryAddressReferences =
      references[REFERENCE_NAME.DELIVERY_ADDRESS];
    const deliveryAddressValues = get(
      values,
      SHIPMENT_FIELDS.DESTINATION_ADDRESS.KEY
    );

    if (!deliveryAddressReferences) {
      const referenceAddress = await loadReferenceAddress(
        deliveryAddressValues
      );
      setReferences(REFERENCE_NAME.DELIVERY_ADDRESS, referenceAddress);
    }
  }, [references.deliveryAddress, setReferences, values]);

  const loadCollectionAddress = useCallback(async () => {
    const collectionAddressReferences =
      references[REFERENCE_NAME.COLLECTION_ADDRESS];
    const collectionAddressValues = get(
      values,
      SHIPMENT_FIELDS.COLLECTION_ADDRESS.KEY
    );

    if (!collectionAddressReferences) {
      const referenceAddress = await loadReferenceAddress(
        collectionAddressValues
      );
      setReferences(REFERENCE_NAME.COLLECTION_ADDRESS, referenceAddress);
    }
  }, [references.deliveryAddress, setReferences, values]);

  return {
    loadDropOffCollection,
    loadDeliveryPickup,
    loadCollectionAddress,
    loadDeliveryAddress,
  };
};
