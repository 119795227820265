import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { userApis } from '../apis';

const initialState = {
  data: {
    consumerId: '',
    email: '',
    phone: '',
    companyName: '',
    VATNumber: '',
    firstName: '',
    lastName: '',
    notifyByEmail: false,
    notifyBySms: false,
    allowTrackingUpdates: false,
  },
  loading: 'idle',
};

export const fetchAccountDetails = createAsyncThunk('user/fetch', async () => {
  const response = await userApis.getAccountDetails();
  return response.data;
});

export const updateAccountDetails = createAsyncThunk(
  'user/update',
  async accountDetailsBody => {
    const response = await userApis.updateAccountDetails({
      email: accountDetailsBody.email,
      phone: accountDetailsBody.phone || null,
      companyName: accountDetailsBody.companyName || null,
      VATNumber: accountDetailsBody.VATNumber || null,
      firstName: accountDetailsBody.firstName,
      lastName: accountDetailsBody.lastName,
    });
    return response.data;
  }
);

export const updateCommunicationPreferences = createAsyncThunk(
  'user/updateCommunicationPreferences',
  async (field, { getState }) => {
    const {
      accountDetails: {
        data: { notifyByEmail, notifyBySms, allowTrackingUpdates },
      },
    } = getState();

    const response = await userApis.updateCommunicationDetails({
      notifyByEmail,
      notifyBySms,
      allowTrackingUpdates,
      ...field,
    });

    return response.data;
  }
);

const deleteAccount = createAsyncThunk('user/delete', async () => {
  const result = await userApis.deleteAccount();

  return result.data;
});

export const getAccountDetailsData = state => state.accountDetails.data;

const getAccountDetailsLoading = state => state.accountDetails.loading;

export const getAccountDetailsFields = createSelector(
  [getAccountDetailsData],
  ({ email, phone, companyName, VATNumber, firstName, lastName }) => ({
    email,
    phone,
    companyName,
    VATNumber,
    firstName,
    lastName,
  })
);

export const getCommunicationPreferences = createSelector(
  [getAccountDetailsData],
  ({ notifyByEmail, notifyBySms, allowTrackingUpdates }) => ({
    notifyByEmail,
    notifyBySms,
    allowTrackingUpdates,
  })
);

export const isPhoneNumberExistSelector = createSelector(
  [getAccountDetailsData],
  ({ phone }) => !!phone
);

export const isAccountDetailsLoading = createSelector(
  [getAccountDetailsLoading],
  loading => loading === 'pending'
);

const slice = createSlice({
  name: 'accountDetails',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAccountDetails.fulfilled, (state, action) => {
      state.data = { ...action.payload };
    });
    builder
      .addCase(updateAccountDetails.pending, state => {
        state.loading = 'pending';
      })
      .addCase(updateAccountDetails.fulfilled, (state, action) => {
        state.data = { ...action.payload };
        state.loading = 'idle';
      })
      .addCase(updateAccountDetails.rejected, state => {
        state.loading = 'idle';
      });
    builder
      .addCase(updateCommunicationPreferences.pending, state => {
        state.loading = 'pending';
      })
      .addCase(updateCommunicationPreferences.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = 'idle';
      })
      .addCase(updateCommunicationPreferences.rejected, state => {
        state.loading = 'idle';
      });
  },
});

export default {
  reducer: slice.reducer,
  actions: {
    fetchAccountDetails,
    updateAccountDetails,
    updateCommunicationPreferences,
    deleteAccount,
  },
  selectors: {
    getAccountDetailsData,
    getAccountDetailsFields,
    getCommunicationPreferences,
    isAccountDetailsLoading,
    isPhoneNumberExistSelector,
  },
};
