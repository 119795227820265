import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
  MenuItem,
  Menu,
  IconButton,
  styled,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import useAnalytics from '../../utils/useAnalytics';
import { BASKET } from '../../constants/analytics';
import { CONFIRMATION } from '../../constants/strings';
import ShareModal from '../../components/ShareModal/ShareModal';
import useBreakpoint from '../../hooks/useBreakpoint';
import { getBarcode } from '../../apis/barcodes';
import getMobileOS from '../../utils/getMobileOS';
import { getShipmentLabelsDownloadUrl } from '../../apis/downloadLabel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ParcelDisplay from '../../components/ParcelDisplay/ParcelDisplay';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '14px',
  minHeight: '36px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
}));

const ActionMenuBtn = ({ actionMenuBtnHandleClick }) => (
  <IconButton
    sx={{ paddingY: 0 }}
    disableRipple
    aria-label='more'
    onClick={actionMenuBtnHandleClick}
  >
    <MoreVertIcon />
  </IconButton>
);

const DropOff = ({ pickupPoint, shipment, shipmentId }) => {
  const theme = useTheme();
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const [openShareModal, setOpenShareModal] = useState(false);

  const { setInterfaceId, Trackable } = useAnalytics();
  const isSmallScreen = useBreakpoint('sm');

  const os = getMobileOS();

  useEffect(() => {
    setInterfaceId(BASKET.INTERFACE_ID);
  }, []);

  const actionEditBtnHandleClick = (e, row) => {
    setActionMenuAnchorEl(e.currentTarget);
  };
  const actionMenuBtnHandleClose = () => {
    setActionMenuAnchorEl(null);
  };

  const fetchBarcode = async () => {
    const data = await getBarcode(shipmentId);
    return data.barcodes;
  };

  return (
    <Trackable loadId={BASKET.LOAD}>
      <Grid sx={{ background: theme.palette.primary.pageBackground, pb: 4 }}>
        <Grid container alignItems='center' justifyContent='center'>
          <Grid
            alignItems='center'
            justifyContent='center'
            spacing={2}
            sx={{ mt: 0 }}
          >
            <Grid item xs={12} md={12}>
              <Grid
                sx={{ mb: 4, mt: 4, px: isSmallScreen ? theme.spacing(1) : 0 }}
                item
              >
                <Typography variant='body1'>
                  {CONFIRMATION.THANK_YOU}
                </Typography>
                <Typography variant='h2' sx={{ mt: 1 }} color=''>
                  {CONFIRMATION.ALL_DONE}
                </Typography>
              </Grid>
              <Grid sx={{ mt: 1, position: 'relative' }}>
                <Paper
                  sx={{ p: 0, maxWidth: isSmallScreen ? '100vw' : '400px' }}
                >
                  <Box
                    sx={{
                      p: 2,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant='h3'>
                      {CONFIRMATION.PARCEL_PASS_LABEL}
                    </Typography>
                    <ActionMenuBtn
                      actionMenuBtnHandleClick={actionEditBtnHandleClick}
                    />
                  </Box>
                  <Menu
                    anchorEl={actionMenuAnchorEl}
                    open={!!actionMenuAnchorEl}
                    onClose={actionMenuBtnHandleClose}
                    MenuListProps={{
                      sx: { width: '189px' },
                    }}
                    anchorOrigin={{
                      horizontal: 'left',
                      vertical: 'bottom',
                    }}
                    transformOrigin={{
                      horizontal: 120,
                      vertical: 90,
                    }}
                  >
                    <StyledMenuItem disableRipple theme={theme}>
                      {CONFIRMATION.DOWNLOAD_LABEL_AS_PDF}
                    </StyledMenuItem>
                    <StyledMenuItem disableRipple theme={theme}>
                      {CONFIRMATION.DOWNLOAD_INVOICE}
                    </StyledMenuItem>
                    <StyledMenuItem
                      disableRipple
                      theme={theme}
                      onClick={() => setOpenShareModal(true)}
                    >
                      {CONFIRMATION.SHARE_PARCEL_INFO}
                    </StyledMenuItem>
                  </Menu>
                  <Divider />
                  <Box
                    sx={{
                      p: 2,
                      py: 2,
                      textAlign: 'center',
                      color: theme.palette.primary.iconGray,
                    }}
                  >
                    <Typography variant='body1'>
                      {CONFIRMATION.TITLE}
                    </Typography>
                  </Box>
                  <ParcelDisplay
                    fetchBarcode={fetchBarcode}
                    isSmallScreen={isSmallScreen}
                    os={os}
                    shipment={shipment}
                    shipmentId={shipmentId}
                  />
                  <Box sx={{ pt: 0, display: 'flex', flexDirection: 'column' }}>
                    <Button
                      target='_blank'
                      href={getShipmentLabelsDownloadUrl(shipmentId)}
                      sx={{ mx: 1, mb: 2, mt: 1 }}
                      variant='contained'
                    >
                      {CONFIRMATION.DOWNLOAD_LABEL_TO_PRINT}
                    </Button>
                    <Button
                      href='/quotes'
                      sx={{ mx: 1, mb: 2 }}
                      variant='outlined'
                    >
                      {CONFIRMATION.SEND_ANOTHER_PARCEL}
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ShareModal
          open={openShareModal}
          onClose={() => setOpenShareModal(false)}
        />
      </Grid>
    </Trackable>
  );
};

DropOff.propTypes = {
  shipment: PropTypes.object,
};

export default DropOff;
