export const REFERENCE_NAME = {
  COLLECTION_DATES: 'collectionDates',
  COLLECTION_PICKUP: 'collectionPickup',
  COLLECTION_ADDRESS: 'collectionAddress',
  DELIVERY_PICKUP: 'deliveryPickup',
  DELIVERY_ADDRESS: 'deliveryAddress',
};

export const USER_TYPE = {
  INDIVIDUAL: 'individual',
  COMPANY: 'company',
};

export const FREE_PROTECTION_DEDUCTION = 50;
