import { useCallback } from 'react';
import { useParams } from 'react-router';
import Loader from '../../features/Loader';
import Error from '../Auth/Error';
import OrderCard from './components/OrderCard';

const OrderSummary = () => {
  const { orderId } = useParams();

  const loadData = useCallback(async ({ orderId }) => {
    // TODO: add call to server
    return [];
  }, []);

  return (
    <Loader
      promiseFn={loadData}
      shipmentId={orderId}
      errorComponent={error => <Error values={{ error }} />}
    >
      {shipments =>
        shipments.map((s, index) => <OrderCard key={index} shipment={s} />)
      }
    </Loader>
  );
};

export default OrderSummary;
