export const THE_PAGE_NOT_FOUND =
  'The page you’re looking for \n can’t be found';
export const PLEASE_TRY_AGAIN = 'Please try again';
export const WE_HAVE_A_LITTLE_PROBLEM = 'We have a little problem';
export const IT_LOOKS_LIKE_WE_ENCOUNTERED =
  'It looks like we encountered an issue and need you to\n log back in.';
export const BACK_TO_LOGIN = 'Back to Login';
export const BACK_TO_HOME = 'Back to homepage';
export const SORRY_YOU_DONT_HAVE_ACCESS =
  'Sorry, you don’t have access to\n this page';
export const IT_LOOKS_LIKE_YOUR_TRYING =
  'It looks like you’re trying to access a page that requires\n you to be logged in.';
export const IF_YOU_RECEIVED_THIS_LINK =
  'If you received this link to track a parcel, please visit the\n tracking page and enter your reference number.';
export const LOGIN = 'Login';
export const TRACK_PARCEL = 'Track parcel';
export const ErrorPageVariant = {
  default: 1,
  crashCases: 2,
  restrictedLink: 3,
  backToHome: 4,
};
