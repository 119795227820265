import { Box, Typography } from '@mui/material';
import FaceThinkingIcon from '../../../components/icons/FaceThinkingIcon';

import { PLEASE_TRY_AGAIN, THE_PAGE_NOT_FOUND } from '../constants';

function NotFoundMessage({
  mainIcon = <FaceThinkingIcon />,
  title = THE_PAGE_NOT_FOUND,
  descriptionArray = [PLEASE_TRY_AGAIN],
  actionContent = null,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingX: { xs: 2, md: 1 },
      }}
    >
      {mainIcon}
      <Typography
        variant={'h2'}
        sx={{
          textAlign: 'center',
          color: theme => theme.palette.text.primary,
          fontFamily: 'Pluto Sans DPD',
          fontSize: '28px',
          fontWeight: 400,
          lineHeight: '120%',
          whiteSpace: 'pre-line',
          paddingY: '12px',
        }}
      >
        {title}
      </Typography>
      {descriptionArray.map(description => (
        <Typography
          variant={'body1'}
          sx={{
            textAlign: 'center',
            color: theme => theme.palette.text.primary,
            fontFamily: 'Pluto Sans DPD',
            fontSize: '16px',
            fontWeight: 370,
            lineHeight: '150%',
            letterSpacing: '0.15px',
            whiteSpace: 'pre-line',
            paddingBottom: '12px',
          }}
          key={description}
        >
          {description}
        </Typography>
      ))}
      {actionContent}
    </Box>
  );
}

export default NotFoundMessage;
