import {
  transformDateWithMessage,
  getParcelStatusContent,
  getParcelNumberContent,
  getParcelDescriptionContent,
} from '../utils/table';

export const ADDRESS_BOOK_TABLE_CONFIG = {
  actionControlsType: 'menu',
  columns: {
    mobile: [
      {
        path: 'details',
        heading: 'Details',
        content: [
          { path: 'nickname' },
          { path: 'contactName' },
          { path: 'addressLine1' },
          { path: 'phoneNumber' },
          { path: 'email' },
        ],
      },
      { path: 'actionDefaultButton', heading: 'Default' },
      { path: 'actionBillingButton', heading: 'Billing' },
      { path: 'actionMenuBtn' },
    ],
    desktop: [
      { path: 'nickname', heading: 'Nickname' },
      { path: 'contactName', heading: 'Contact Name' },
      { path: 'addressLine1', heading: 'Address' },
      { path: 'phoneNumber', heading: 'Phone Number' },
      { path: 'email', heading: 'Email Address' },
      { path: 'actionDefaultButton', heading: 'Default' },
      { path: 'actionBillingButton', heading: 'Billing' },
      { path: 'actionMenuBtn' },
    ],
  },
};

export const OVERVIEW_ADDRESS_BOOK_TABLE_CONFIG = {
  actionControlsType: 'menu',
  columns: {
    mobile: [
      {
        path: 'details',
        heading: 'Details',
        content: [
          { path: 'nickname' },
          { path: 'contactName' },
          { path: 'addressLine1' },
        ],
      },
      { path: 'actionMenuBtn' },
    ],
    desktop: [
      { path: 'nickname', heading: 'Nickname' },
      { path: 'contactName', heading: 'Contact Name' },
      { path: 'addressLine1', heading: 'Address' },
      { path: 'actionMenuBtn' },
    ],
  },
};

export const ADDRESS_BOOK_MODAL_TABLE_CONFIG = {
  actionControlsType: 'selectButton',
  columns: {
    mobile: [
      {
        path: 'details',
        heading: 'Details',
        content: [
          { path: 'nickname' },
          { path: 'contactName' },
          { path: 'addressLine1' },
          { path: 'phoneNumber' },
          { path: 'email' },
        ],
      },
      { path: 'actionSelectedButton' },
    ],
    desktop: [
      { path: 'nickname', heading: 'Nickname' },
      { path: 'contactName', heading: 'Contact Name' },
      { path: 'addressLine1', heading: 'Address' },
      { path: 'email', heading: 'Email Address' },
      { path: 'actionSelectedButton' },
    ],
  },
};

export const PARCELS_TABLE_CONFIG = {
  actionControlsType: 'menu',
  columns: {
    mobile: [
      {
        path: 'details',
        heading: 'Details',
        content: [
          { path: 'parcelNumber' },
          { path: 'recipient' },
          { path: 'postcode' },
          { path: 'description', getter: getParcelDescriptionContent },
          {
            path: 'ordered',
            getter: transformDateWithMessage('Ordered'),
          },
          {
            path: 'collected',
            getter: transformDateWithMessage('Collected'),
          },
          { path: 'status', getter: getParcelStatusContent },
        ],
      },
      { path: 'actionMenuBtn' },
    ],
    desktop: [
      {
        path: 'parcelNumber',
        getter: getParcelNumberContent,
        heading: 'Parcel No.',
      },
      { path: 'recipient', heading: 'Recipient' },
      { path: 'postcode', heading: 'Postcode' },
      {
        path: 'description',
        getter: getParcelDescriptionContent,
        heading: 'Description',
      },
      {
        path: 'ordered',
        getter: transformDateWithMessage('Ordered'),
        heading: 'Ordered',
      },
      {
        path: 'collected',
        getter: transformDateWithMessage('Collected'),
        heading: 'Collected',
      },
      { path: 'status', getter: getParcelStatusContent, heading: 'Tracking' },
      { path: 'actionMenuBtn' },
    ],
  },
};

export const RECENT_PARCELS_TABLE_CONFIG = {
  actionControlsType: 'menu',
  columns: {
    mobile: [
      {
        path: 'details',
        heading: 'Details',
        content: [
          { path: 'recipient' },
          { path: 'description', getter: getParcelDescriptionContent },
          { path: 'status', getter: getParcelStatusContent },
        ],
      },
      { path: 'actionMenuBtn' },
    ],
    desktop: [
      { path: 'recipient', heading: 'Recipient' },
      {
        path: 'description',
        getter: getParcelDescriptionContent,
        heading: 'Description',
      },
      { path: 'status', getter: getParcelStatusContent, heading: 'Tracking' },
      { path: 'actionMenuBtn' },
    ],
  },
};
