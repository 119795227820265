import { useNavigate } from 'react-router';
import { useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  HOME,
  NOT_FOUND,
  SIGN_IN_WITH_EMAIL,
  TRACK,
} from '../../constants/routes';
import FacePensiveIcon from '../../components/icons/FacePensiveIcon';
import {
  BACK_TO_HOME,
  ErrorPageVariant,
  IF_YOU_RECEIVED_THIS_LINK,
  IT_LOOKS_LIKE_WE_ENCOUNTERED,
  IT_LOOKS_LIKE_YOUR_TRYING,
  LOGIN,
  SORRY_YOU_DONT_HAVE_ACCESS,
  TRACK_PARCEL,
  WE_HAVE_A_LITTLE_PROBLEM,
} from './constants';
import NotFoundMessage from './components/NotFoundMessage';

function NotFound({ variant = ErrorPageVariant.default, error }) {
  const navigate = useNavigate();

  useEffect(() => {
    variant === ErrorPageVariant.default && navigate(NOT_FOUND);
  }, []);

  const content = useCallback((variant, error) => {
    switch (variant) {
      case ErrorPageVariant.crashCases:
        return (
          <NotFoundMessage
            title={WE_HAVE_A_LITTLE_PROBLEM}
            descriptionArray={[IT_LOOKS_LIKE_WE_ENCOUNTERED]}
            mainIcon={<FacePensiveIcon />}
            actionContent={
              <Button
                variant={'contained'}
                onClick={() => navigate(SIGN_IN_WITH_EMAIL)}
              >
                {BACK_TO_HOME}
              </Button>
            }
          />
        );
      case ErrorPageVariant.backToHome:
        return (
          <NotFoundMessage
            title={WE_HAVE_A_LITTLE_PROBLEM}
            descriptionArray={[error]}
            mainIcon={<FacePensiveIcon />}
            actionContent={
              <Button variant={'contained'} onClick={() => navigate(HOME)}>
                {BACK_TO_HOME}
              </Button>
            }
          />
        );
      case ErrorPageVariant.restrictedLink:
        return (
          <NotFoundMessage
            title={SORRY_YOU_DONT_HAVE_ACCESS}
            descriptionArray={[
              IT_LOOKS_LIKE_YOUR_TRYING,
              IF_YOU_RECEIVED_THIS_LINK,
            ]}
            actionContent={
              <Box sx={{ display: 'flex' }}>
                <Button
                  variant='contained'
                  sx={{ marginRight: '12px' }}
                  onClick={() => navigate(SIGN_IN_WITH_EMAIL)}
                >
                  {LOGIN}
                </Button>
                <Button variant='outlined' onClick={() => navigate(TRACK)}>
                  {TRACK_PARCEL}
                </Button>
              </Box>
            }
            mainIcon={<FacePensiveIcon />}
          />
        );
      case ErrorPageVariant.default:
      default:
        return <NotFoundMessage />;
    }
  }, []);

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      minHeight='100vh'
    >
      {content(variant, error)}
    </Box>
  );
}

export default NotFound;
