import { useState, useEffect } from 'react';
import {
  getPickupLocations,
  getPickupLocationsByAddress,
} from '../apis/locationsApis';
import { MAX_DISTANCE, SEARCH_PAGE_SIZE } from '../components/MapBox/constants';

const usePickupLocations = ({
  latitude = null,
  longitude = null,
  searchAddress = null,
  searchCriteria = null,
  maxDistance = MAX_DISTANCE,
  searchPageSize = SEARCH_PAGE_SIZE,
  searchPage = 1,
  countryCode = 'GB',
}) => {
  const [pickupLocations, setPickupLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [kinds, setKinds] = useState([]);

  useEffect(() => {
    if (!searchAddress && (latitude === null || longitude === null)) {
      return;
    }

    const fetchPickupLocations = async () => {
      setLoading(true);
      setError(null);

      try {
        const options = {
          maxDistance,
          searchPageSize,
          searchPage,
          countryCode,
        };

        let data;
        if (searchAddress) {
          const addressOptions = {
            searchAddress,
            searchCriteria,
            ...options,
          };
          data = await getPickupLocationsByAddress(addressOptions);
        } else if (latitude !== null && longitude !== null) {
          data = await getPickupLocations({ latitude, longitude }, options);
        } else {
          throw new Error('No valid location or address provided');
        }

        const transformedPickupLocations = data.map(item => ({
          distance: item.distance,
          pickupLocation: item.pickupLocation,
        }));

        setPickupLocations(transformedPickupLocations);

        // Extract unique kinds of Pickup Points
        const uniqueKinds = [
          ...new Set(data.map(item => item.pickupLocation.kind)),
        ];
        setKinds(uniqueKinds);
      } catch (error) {
        setError(error.message || 'An error occurred');
        setPickupLocations([]);
      } finally {
        setLoading(false);
      }
    };

    fetchPickupLocations();
  }, [
    latitude,
    longitude,
    searchAddress,
    searchCriteria,
    maxDistance,
    searchPageSize,
    searchPage,
    countryCode,
  ]);

  return { pickupLocations, loading, error, kinds, setPickupLocations };
};

export default usePickupLocations;
