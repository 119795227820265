import Async from 'react-async';
import NotFound from '../../pages/NotFound';
import { ErrorPageVariant } from '../../pages/NotFound/constants';
import Pending from './Pending';

const Loader = ({
  children,
  promiseFn,
  errorComponent = error =>
    NotFound({ variant: ErrorPageVariant.backToHome, error: error.message }),
  pendingProps,
  ...args
}) => (
  <Async promiseFn={promiseFn} {...args}>
    <Async.Pending>
      <Pending {...pendingProps} />
    </Async.Pending>
    <Async.Fulfilled>{children}</Async.Fulfilled>
    <Async.Rejected>{errorComponent}</Async.Rejected>
  </Async>
);

Loader.propTypes = Async.propTypes;

export default Loader;
