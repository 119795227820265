import Joi from 'joi';
import { set } from 'lodash';
import {
  PHONE_NUMBER_REGEXP,
  UK_POST_CODE_FULL,
  EMAIL_REGEX,
  COMMON_PHONE_NUMBER_REGEXP,
  BFPO_POSTCODES,
} from '../constants/regex';
import * as M from '../constants/strings';
import {
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  CITY,
  COUNTRY,
  COUNTY,
  HEIGHT,
  LENGTH,
  ORGANISATION,
  POSTAL_ZIP_CODE,
  QUANTITY,
  WEIGHT,
  WIDTH,
  CONTACT_NAME,
  NICKNAME,
  EMAIL,
  PHONE_NUMBER,
  ACCOUNT_DETAILS_FIELDS,
} from '../constants/forms';

export const isNonEmptyString = value => /\S/.test(value);

export const required = value => (value ? undefined : M.REQUIRED);
export const notEmpty = value => {
  if ((value || '').length) {
    return isNonEmptyString(value) ? undefined : M.SHOULD_CONTAIN_ONE_CHARACTER;
  }
  return undefined;
};

export const mustBeNumber = value =>
  isNaN(value) ? M.MUST_BE_NUMBER : undefined;

// we don't want to check if value is exist here, it's only for format validation, so `!val` is present
export const isPostcodeValid = postcode => UK_POST_CODE_FULL.test(postcode);

export const isEmailValid = value => EMAIL_REGEX.test(value);
// we don't want to check if value is exist here, it's only for format validation, so `!val` is present
export const postcode = postcode =>
  isPostcodeValid(postcode) ? undefined : M.INVALID_POSTCODE;

export const phoneNumber = value =>
  PHONE_NUMBER_REGEXP.test(value) ? undefined : M.INVALID_MOBILE_NUMBER;

export const email = email =>
  email && !isEmailValid(email) ? M.INVALID_EMAIL : undefined;

export const maxLength = max => value =>
  value && value.length > max ? M.LESS_OR_$_CHARACTERS(max) : undefined;

export const minLength = min => value =>
  value && value.length < min ? M.GREATER_OR_$_CHARACTERS(min) : undefined;

export const maxValue = max => value =>
  value && !isNaN(value) && value > max
    ? M.LESS_THAN_OR_$_NUMBER(max)
    : undefined;

export const minValue = min => value =>
  value && !isNaN(value) && value < min
    ? M.LESS_THAN_OR_$_NUMBER(min)
    : undefined;

export const composeValidators =
  (...validators) =>
  (value, allValues, meta) =>
    validators.reduce(
      (error, validator) => error || validator(value, allValues, meta),
      undefined
    );

// Validator for Joi with react-final-form integration
export const validateWithJoi = (values, schema, options = {}) => {
  let errors = {};
  const { error } = schema.validate(values, {
    abortEarly: false,
    errors: { wrap: { label: false } },
    ...options,
  });
  if (error) {
    errors = error.details.reduce((all, cur) => {
      const allErrors = Object.assign({}, all);
      set(allErrors, cur.path.join('.'), cur.message);

      return allErrors;
    }, {});
  }

  return errors;
};

export const requireKeysSchema = (schema, requireKeys = []) => {
  const makeRequired = x => x.required().empty('', null);

  return schema.fork(requireKeys, makeRequired);
};

export const getJoiFieldFlag = (schema, fieldName, validationFlag) => {
  // Get schema flags
  const fieldSchema = schema.extract(fieldName);

  // Check field validation flag
  return fieldSchema?._flags?.presence === validationFlag;
};

export const isFieldRequired = (schema, fieldName) =>
  getJoiFieldFlag(schema, fieldName, 'required');

const numberOfParcels = Joi.number().min(1).max(99).required().label(QUANTITY);
const totalWeight = Joi.number().greater(0).required();

export const bfpoValidation = (value, helpers) => {
  if (BFPO_POSTCODES.some(regex => regex.test(value))) {
    return helpers.error('postcode.bfpo.invalid');
  }
  return value;
};

const addressSchema = {
  street: Joi.string().max(35).required().label(ADDRESS_LINE_1),
  locality: Joi.string().max(35).allow(null, '').label(ADDRESS_LINE_2),
  town: Joi.string().max(35).required().label(CITY),
  county: Joi.string().max(35).allow(null, '').label(COUNTY),
  postcode: Joi.string()
    .max(8)
    .required()
    .label(POSTAL_ZIP_CODE)
    .custom(bfpoValidation)
    .messages(M.CUSTOM_VALIDATION_ERROR_MESSAGES),
  countryCode: Joi.string().required().label(COUNTRY),
};

const contactSchema = {
  contactName: Joi.string().max(35).required().label(CONTACT_NAME),
  nickname: Joi.string().max(35).required().label(NICKNAME),
  email: Joi.string()
    .pattern(EMAIL_REGEX)
    .required()
    .max(100)
    .messages({
      'string.pattern.base': 'Please enter a valid email',
    })
    .label(EMAIL),
  phoneNumber: Joi.string()
    .pattern(COMMON_PHONE_NUMBER_REGEXP)
    .required()
    .max(15)
    .messages({
      'string.pattern.base': 'Please enter a valid phone number',
    })
    .label(PHONE_NUMBER),
};

export const addressDetailsSchema = Joi.object({
  ...addressSchema,
  organisation: Joi.string().max(35).label(ORGANISATION),
  locality: Joi.string().max(35).allow(null, '').label(ADDRESS_LINE_2),
  coordinates: Joi.object({
    latitude: Joi.string(),
    longitude: Joi.string(),
  }).required(),
  fullAddress: Joi.object().required(),
  activePickupPoint: Joi.object(),
});

export const accountDetailsSchema = Joi.object({
  [ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.FIRST_NAME.NAME]: Joi.string()
    .max(15)
    .required()
    .label(ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.FIRST_NAME.LABEL),
  [ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.LAST_NAME.NAME]: Joi.string()
    .max(15)
    .required()
    .label(ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.LAST_NAME.LABEL),
  [ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.EMAIL_ADDRESS.NAME]: Joi.string()
    .pattern(EMAIL_REGEX)
    .required()
    .max(100)
    .label(ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.EMAIL_ADDRESS.LABEL)
    .messages({
      'string.pattern.base': 'Please enter a valid email',
    }),
  [ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.PHONE_NUMBER.NAME]: Joi.string()
    .pattern(PHONE_NUMBER_REGEXP)
    .allow(null, '')
    .messages({
      'string.pattern.base': 'Please enter a valid phone number',
    })
    .label(ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.PHONE_NUMBER.LABEL),
  [ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.COMPANY_NAME.NAME]: Joi.string()
    .max(35)
    .allow(null, '')
    .label(ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.COMPANY_NAME.LABEL),
  [ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.VAT_NUMBER.NAME]: Joi.string()
    .max(45)
    .min(4)
    .allow(null, '')
    .label(ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.VAT_NUMBER.LABEL),
});

export const addressBookContactSchema = Joi.object(contactSchema);
export const addressBookAddressSchema = Joi.object(addressSchema);

export const addressBookSchema = Joi.object({
  address: Joi.object(addressSchema),
  ...contactSchema,
});

export const getNetworksSchema = Joi.object({
  deliveryDetails: Joi.object({
    address: Joi.object({
      countryCode: Joi.string().required(),
      town: Joi.string().max(35).required(),
      county: Joi.string().max(35).allow(null, ''),
      postcode: Joi.string().required(),
    }).required(),
  }).required(),
  collectionDetails: Joi.object({
    address: Joi.object({
      countryCode: Joi.string().required(),
      town: Joi.string().max(35).required(),
      county: Joi.string().max(35).allow(null, ''),
      postcode: Joi.string().required(),
    }).required(),
  }).required(),
  numberOfParcels,
  totalWeight,
}).required();

export const parcelDetailsSchema = (maxWeight, maxParcelNumber) =>
  Joi.object({
    deliveryDetails: {
      address: addressDetailsSchema,
      activePickupPoint: Joi.any(),
    },
    collectionDetails: {
      address: addressDetailsSchema,
      activePickupPoint: Joi.any(),
      dropAtShop: Joi.boolean().allow(null),
    },
    numberOfParcels,
    totalWeight,
    parcelDestination: Joi.string().required(),
    networkCode: Joi.string().required(),
    insurance: Joi.number().required(),
    parcels: Joi.array().items(
      Joi.object({
        id: Joi.string().allow(null, ''),
        numberOfParcels: numberOfParcels.max(maxParcelNumber || 99),
        parcelSize: Joi.number()
          .greater(0)
          .max(maxWeight || 30)
          .required()
          .label(WEIGHT),
        height: Joi.number().greater(0).max(100).required().label(HEIGHT),
        length: Joi.number().greater(0).max(100).required().label(LENGTH),
        width: Joi.number().greater(0).max(100).required().label(WIDTH),
        deliveryDescription: Joi.string().allow('', null),
        totalWeight,
      })
    ),
  });

export const voucherSchema = Joi.object({
  voucherCode: Joi.string()
    .min(3)
    .messages({
      'string.min': M.GREATER_OR_$_CHARACTERS(3),
    }),
});
